import { useQuery, UseQueryResult } from "@tanstack/react-query";
import klydoApiClient, { KlydoSearchFilter } from "api/klydo";

const useKlydos = (
  query: string,
  filters: KlydoSearchFilter,
): UseQueryResult<string[]> => {
  const { excludeIds } = filters;
  return useQuery({
    queryKey: ["klydos", query, excludeIds],
    queryFn: async () => {
      return klydoApiClient
        .search(query, { excludeIds })
        .then((res) => res.ids);
    },
    initialData: excludeIds,
    enabled: !!query,
  });
};

export default useKlydos;
