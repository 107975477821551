import { useState, useEffect } from "react";

// react-bootstrap components
import { Button, Spinner } from "react-bootstrap";
import firebaseService from "../firebase_service/firebaseService";
import MsTable from "../components/Widgets/Table";
import { Log } from "Types";
import { colType } from "../Types";

function Logs() {
  const [data, setData] = useState<Array<Log>>();
  useEffect(() => {
    const l = (logs: Log[]) => {
      setData([...logs]);
    };
    firebaseService.listen<Log>("errors", l);
    return () => firebaseService.removeListener("errors", l);
  }, []);

  type localLog = Log & { count: number; ids: Array<string> };
  useEffect(() => {
    let newData = Array<localLog>();
    data?.forEach((d) => {
      const logIndex = newData.findIndex(
        (n) => n.message === d.message && n.version === d.version,
      );
      if (logIndex === -1) {
        newData.push({ ids: [d.id], count: 1, ...d });
      } else {
        newData[logIndex].count = newData[logIndex].count + 1;
        newData[logIndex].ids.push(d.id);
      }
    });
    setFilteredData(newData);
  }, [data]);
  const [filteredData, setFilteredData] = useState<Array<localLog>>([]);

  const tableColumns: Array<colType> = [
    {
      sort: true,
      label: "Version",
      field: "version",
    },
    {
      scroll: true,
      sort: true,
      label: "Message",
      field: "message",
    },
    {
      scroll: true,
      size: 700,
      sort: true,
      label: "Log",
      field: "stack",
    },
    {
      sort: false,
      label: "Count",
      field: "count",
    },
    {
      sort: true,
      label: "Log Date",
      field: "error_time",
    },
    {
      sort: false,
      label: "",
      field: "dismiss",
    },
  ];

  const tableRows = (r: localLog, key: number) => {
    return {
      version: r.version,
      message: r.message,
      stack: r.stack,
      count: r.count,
      error_time: r.error_time.toLocaleString("en-GB"),
      dismiss: (
        <Button
          style={{ margin: "8px" }}
          onClick={() => {
            firebaseService.setDismissed(r.ids);
          }}
        >
          Dismiss
        </Button>
      ),
      key: key,
    };
  };

  return (
    <>
      {data && filteredData ? (
        <MsTable
          id="logs"
          rowBuild={tableRows}
          data={filteredData}
          search={(row: any, val: string) => {
            if (parseInt(val)) {
              return row.version === parseInt(val);
            }
            return (
              row.message.toLowerCase().includes(val) ||
              row.stack.toLowerCase().includes(val)
            );
          }}
          initSort={"version"}
          cols={tableColumns}
          title="Logs"
        />
      ) : (
        <Spinner></Spinner>
      )}
    </>
  );
}

export default Logs;
