import { Button, Modal } from "react-bootstrap";
import { useWebSocket } from "../components/hooks/useWebSocket";
import { useEffect } from "react";

interface LogcatStreamModalProps {
  deviceId: string;
  onClose: () => void;
}

const LogcatStreamModal: React.FC<LogcatStreamModalProps> = ({
  deviceId,
  onClose,
}) => {
  const uri = "wss://clock-socket-917257785826.us-central1.run.app/";
  const {
    start,
    stop,
    sendCommand,
    isConnected,
    isReady,
    loadingMessage,
    logElement,
    clearLog,
  } = useWebSocket({ uri, devId: deviceId });

  useEffect(() => {
    start();
  }, []);

  const handleClear = () => {
    sendCommand("clear");
    clearLog();
  };

  const handleRead = () => {
    sendCommand("start");
  };

  const handleStop = () => {
    stop();
    onClose();
  };

  return (
    <Modal show onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Logcat Stream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button onClick={handleRead} disabled={!isReady}>
            Read
          </Button>
          <Button onClick={() => sendCommand("pause")} disabled={!logElement}>
            Pause
          </Button>
          <Button onClick={handleClear} disabled={!logElement}>
            Clear
          </Button>
          <Button onClick={handleStop} disabled={!isConnected}>
            Stop
          </Button>
        </div>

        <div
          id="log"
          ref={logElement}
          style={{
            overflowY: "auto",
            padding: "10px",
            height: "60vh",
            backgroundColor: "#1e1e1e",
            color: "#00ff00",
            fontFamily: "monospace",
          }}
        >
          {loadingMessage ? <p>{loadingMessage}</p> : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogcatStreamModal;
