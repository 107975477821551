import axios from "axios";

export type UploadFileInput = {
  file: string;
  folder: string;
  upload_preset?: string;
};

const uploadFile = async (input: UploadFileInput) => {
  const res = await axios.post(
    "https://api.cloudinary.com/v1_1/KlydoClock/auto/upload",
    {
      file: input.file,
      folder: input.folder,
      upload_preset: input.upload_preset || "Bezalel_preset",
    },
  );
  return res.data.secure_url;
};

export default uploadFile;
