import { Pack, Review, ReviewResponse } from "Types";
import firebaseService from "./firebaseService";
import firebase from "firebase/compat/app";

class PackService {
  private firebaseService = firebaseService;

  async acceptPackReview(pack: Pack, dlt: boolean, message?: string) {
    const batch = firebaseService.db.batch();
    const doc = firebaseService.db.collection("draftPacks").doc(pack.id);
    const timeStemp = firebase.firestore.FieldValue.serverTimestamp();
    const results: Review = {
      started: pack.review?.date ?? firebaseService.toDate(timeStemp),
      ended: firebaseService.toDate(timeStemp),
      approved: message ? false : true,
      type: dlt ? "delete" : "publish",
    };
    if (message) results.msg = message;
    batch.set(doc.collection("reviews").doc(), results);
    const reviewResponse: ReviewResponse = {
      result: results,
      review: firebase.firestore.FieldValue.delete(),
    };
    if (!message) reviewResponse.edited = false;
    batch.update(doc, reviewResponse);
    const updatedPack: Partial<Pack> = {
      createdAt: pack.createdAt,
      creator: pack.creatorId,
      logoUrl: pack.logoUrl,
      name: pack.name,
      description: pack.description,
      imageUrl: pack.imageUrl,
      pendulumImageUrl: pack.pendulumImageUrl,
      klydos: pack.klydos,
      updatedAt: firebaseService.toDate(timeStemp),
    };
    if (!message && !dlt)
      batch.set(
        firebaseService.db.collection("packs").doc(pack.id),
        updatedPack,
        {
          merge: true,
        },
      );
    if (dlt) {
      batch.delete(firebaseService.db.collection("packs").doc(pack.id));
    }
    await batch.commit();
    firebaseService.data.packsReviews.data?.splice(
      firebaseService.data.packsReviews.data?.indexOf(pack),
      1,
    );
    if (firebaseService.data.klydos.data) {
      if (!message && !dlt && firebaseService.data.packs?.data)
        firebaseService.data.packs.data.push({ ...pack, ...updatedPack });
      if (dlt)
        if (firebaseService.data.packs?.data) {
          firebaseService.data.packs.data.splice(
            firebaseService.data.packs.data.indexOf(pack),
            1,
          );
        }
      firebaseService.data.packs.trigger();
    }

    firebaseService.data.packsReviews.trigger();
  }

  public async updatePack(packId: string, pack: Partial<Pack>): Promise<void> {
    await this.firebaseService.db.collection("packs").doc(packId).update(pack);
  }

  public async upsertPack(
    pack: Omit<Pack, "id"> & { id?: string },
  ): Promise<string> {
    if (pack.id) {
      await this.firebaseService.db
        .collection("packs")
        .doc(pack.id)
        .update(pack);
      return pack.id;
    } else {
      const result = await this.firebaseService.db
        .collection("packs")
        .add(pack);
      return result.id;
    }
  }
}
const packService = new PackService();
export default packService;
