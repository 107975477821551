import { colType, CommandServer } from "Types";
import MsTable from "components/Widgets/Table";
import { useState, useEffect } from "react";
import firebaseService from "../firebase_service/firebaseService";
import EventModal from "./EventModal";
import { Spinner } from "react-bootstrap";

function Events() {
  const [data, setData] = useState<Array<CommandServer>>();

  useEffect(() => {
    const l = (events: Array<CommandServer>) => {
      setData([...events]);
    };
    firebaseService.listen<CommandServer>("commandsHistory", l);
    return () => {
      firebaseService.removeListener("commandsHistory", l);
    };
  }, []);

  const tableColumns: Array<colType> = [
    {
      sort: true,
      label: "Id",
      field: "id",
    },
    {
      sort: true,
      label: "Name",
      field: "name",
    },
    {
      sort: true,
      label: "Time",
      field: "time",
    },
    {
      sort: true,
      label: "Timeout",
      field: "timeout",
    },
    {
      sort: true,
      label: "Sent amnt",
      field: "devices",
    },
    {
      sort: true,
      label: "Recipient amnt",
      field: "recipientAmount",
    },
    {
      sort: false,
      label: "Result",
      field: "result",
    },
  ];

  const tableRows = (r: CommandServer, key: number) => {
    return {
      id: r.id,
      name: r.name,
      time: (
        <p>
          {r.time.toLocaleString("en-GB")}{" "}
          {r.cmdHistory[0].local ? "Local" : "GMT"}
        </p>
      ),
      timeout: r.timeout,
      devices: r.devices?.length,
      recipientAmount: r.cmdHistory.length,
      result: Array.from(
        r.cmdHistory.reduce((map: Map<string, number>, cur) => {
          const curResult = cur.result;
          if (!map.has(curResult)) map.set(curResult, 0);
          map.set(curResult, map.get(curResult)! + 1);
          return map;
        }, new Map<string, number>()),
      ).map((entry) => (
        <div>
          <p>
            {entry[0]} : {entry[1]}
          </p>
        </div>
      )),
      key: key,
    };
  };

  return (
    <>
      {data ? (
        <MsTable
          id="events"
          rowBuild={tableRows}
          data={data!}
          search={(row: any, val: string) => {
            if (parseInt(val)) {
              return row.version === parseInt(val);
            }
            return (
              row.message.toLowerCase().includes(val) ||
              row.stack.toLowerCase().includes(val)
            );
          }}
          initSort="cmdId"
          cols={tableColumns}
          modal={EventModal}
          title="Events"
        />
      ) : (
        <Spinner></Spinner>
      )}
    </>
  );
}

export default Events;
