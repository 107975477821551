import countryList from "react-select-country-list";
import { colType, Klydo, Review, SocialNetwork, User } from "../Types";
import MsTable from "components/Widgets/Table";
import firebaseService from "firebase_service/firebaseService";
import { useEffect, useState, useRef, useMemo } from "react";
import { Form, Modal, Button, Row, Col, Spinner } from "react-bootstrap";
import FloatAddButton from "components/Widgets/FloatAddButton";
import ButtonLoader from "components/Widgets/ButtonLoader";
import place_holder from "assets/img/drag_placholder.png";
import clock_profile from "assets/img/klydoclock_profile.png";
import { roles } from "../Types";
import { statesList } from "../utils/util";
import UserModal from "./UserModal";
import anonymousPhoto from "assets/img/anonymous.png";
import SocialNetworkEdit from "components/Widgets/SocialNetworkEdit";
import Editable from "components/Widgets/Editable";
import useUsers from "components/hooks/useUsers";

export const descriptionMaxLength = 420;

function Users() {
  const data = useUsers();
  const [klydos, setKlydos] = useState<Array<Klydo>>();
  const [reviews, setReviews] = useState<Array<Klydo>>();
  const [showAdd, setShowAdd] = useState(false);
  const [toRemove, setToRemove] = useState<
    { uid: string; role: "admin" | "editor" | "clock" } | undefined
  >();
  const [warning, setWarning] = useState("");
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [description, setDescription] = useState("");
  const [socialNetworkList, setSocialNetworkList] = useState<SocialNetwork[]>(
    [],
  );
  const [countChar, setCountChar] = useState<number>();
  const [role, setRole] = useState("editor");
  const [picURL, setPicURL] = useState<any>();
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadPic, setLoadPic] = useState(false);

  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    pic: "",
    description: "",
  });
  const formRef = useRef<any>(null);

  const [country, setCountry] = useState("EA");
  const [state, setState] = useState("AL");
  const countrys = useMemo(() => {
    const r = countryList().getData();
    r.find((c) => c.value === "EA") || r.push({ value: "EA", label: "Earth" });
    r.sort((a, b) => (a.label > b.label ? 1 : -1));
    return r;
  }, []);
  useEffect(() => {
    document.getElementById("newUserForm")?.setAttribute("class", "");
  }, [showAdd]);
  function resetAll() {
    setName("");
    setEmail("");
    setPassword("");
    setRole("editor");
    setDescription("");
    setSocialNetworkList([]);
    setPicURL("");
    setCountry("IL");
    setState("AL");
    setLoading(false);
    setError({ name: "", email: "", password: "", pic: "", description: "" });
    setShowAdd(false);
  }
  function closeConfirm() {
    setWarning("");
    setToRemove(undefined);
  }
  function checkEmail(e: string) {
    const nameAndDomaian = e.split("@");
    if (nameAndDomaian.length !== 2) return false;
    if (nameAndDomaian[0] === "" || nameAndDomaian[1] === "") return false;
    const dom = nameAndDomaian[1].split(".");
    if (dom.length < 2 || dom.length > 3) return false;
    if (dom.reduce((p, c) => (p = p ? true : c === ""), false)) return false;
    return true;
  }

  useEffect(() => {
    firebaseService.getData<Klydo>("klydos").then(setKlydos);
    firebaseService.getData<Klydo>("reviews").then(setReviews);
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    const err = { name: "", email: "", password: "", pic: "", description: "" };
    if (!form.checkValidity()) {
      if (name === "") err.name = "Please enter a name";
      if (role !== "clock" && !checkEmail(email))
        err.email = "Please enter a valid email";
      if (password.length < 8)
        err.password = "Please enter a pasword with 8 or more characters";
      if (description.length > descriptionMaxLength)
        err.description = `Max length is ${descriptionMaxLength} charecters`;
      setError(err);
      event.stopPropagation();
    } else {
      setError(err);
      setLoading(true);
      firebaseService
        .addUser(
          email,
          name,
          password,
          role,
          country,
          state,
          description,
          socialNetworkList,
          imageUrl,
        )
        .then(() => {
          setValidated(true);
          resetAll();
        })
        .catch((e) => {
          setLoading(false);
          console.log("e:", e);
          setError({
            name: "",
            email: "Something went wrong - user was not added",
            password: "",
            pic: "",
            description: "",
          });
        });
    }
  };
  const tableColumns: Array<colType> = [
    {
      sort: true,
      label: "Name",
      field: "displayName",
    },
    {
      size: 300,
      sort: true,
      label: "Email",
      field: "email",
    },
    {
      sort: true,
      label: "Role",
      field: "role",
    },
    {
      size: 300,
      sort: true,
      label: "ID",
      field: "uid",
    },
    {
      sort: true,
      label: "Last Used",
      field: "used",
    },
    {
      size: 48,
      sort: false,
      label: "Profile picture",
      field: "pic",
    },
    {
      sort: true,
      label: "Tag",
      field: "tag",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];

  const tableRows = (r: User, key: number) => {
    return {
      uid: r.uid,
      displayName: r.displayName,
      email: r.email,
      role: r.role,
      pic:
        r.role === "clock" ? (
          <img
            style={{ borderRadius: 50 + "%" }}
            loading="lazy"
            height={48}
            width={48}
            alt="profile"
            src={clock_profile}
          ></img>
        ) : (
          <img
            style={{ borderRadius: 50 + "%" }}
            loading="lazy"
            height={48}
            width={48}
            alt="profile"
            src={r.photoURL ?? anonymousPhoto}
          ></img>
        ),
      used: r.used.toLocaleString("en-GB"),
      delete: (
        <i
          style={{ margin: 8 + "px" }}
          className="nc-icon nc-simple-remove"
          onClick={(e) => {
            e.stopPropagation();
            setToRemove({ uid: r.uid, role: r.role });
          }}
        />
      ),
      tag: (
        <Editable
          title="tag"
          default={r.basicUserInfo?.tag || ""}
          onSet={(value) => {
            return new Promise((v, x) => {
              firebaseService
                .updateUserProfile(
                  { fb: "tag", user: null, value: value },
                  r.uid,
                )
                .then(() => {
                  if (r.basicUserInfo) r.basicUserInfo!.tag = value;
                  else r.basicUserInfo = { tag: value };
                })
                .catch(x);
            });
          }}
        ></Editable>
      ),
      key: key,
    };
  };
  return (
    <>
      {data ? (
        <MsTable
          id="users"
          rowBuild={tableRows}
          initSort="displayName"
          search={(row: User, val: string) => {
            if (roles.includes(val)) return row.role === val;
            if (row.basicUserInfo?.tag == val) return true;
            if (val === "//activeCreators") {
              return !!klydos?.find((k) => k.creator === row.uid);
            }
            if (val === "//activeEditors")
              return row.role == "editor" && !!row.used.getTime();
            return (
              row.displayName?.toLowerCase()?.includes(val) ||
              row.email?.toLowerCase()?.includes(val) ||
              row.uid.toLowerCase().includes(val) ||
              row.role.includes(val)
            );
          }}
          cols={tableColumns}
          data={data!}
          title="Admins"
          modal={UserModal}
        />
      ) : (
        <Spinner></Spinner>
      )}
      <FloatAddButton onClick={() => setShowAdd(true)} />
      <Modal show={showAdd} size="lg" onHide={resetAll}>
        <Modal.Header style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}>
          <h4>Add a new user</h4>
          <i
            className="nc-icon nc-simple-remove"
            style={{ marginTop: 15 }}
            onClick={resetAll}
          ></i>
        </Modal.Header>
        <Modal.Body>
          {role !== "clock" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: 200,
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span style={{ position: "absolute" }}>
                  {loadPic ? (
                    <Spinner animation="border" />
                  ) : (
                    <img
                      referrerPolicy="no-referrer"
                      style={{ borderRadius: 50 + "%" }}
                      loading="eager"
                      height={150}
                      width={150}
                      alt="profile"
                      src={picURL || place_holder}
                    />
                  )}
                </span>
                <div style={{ height: 15, width: 15 }}></div>
                <input
                  id="user-photo"
                  onChange={(e) => {
                    setLoadPic(true);
                    const fr = new FileReader();
                    fr.onload = function () {
                      setLoadPic(false);
                      setPicURL(fr.result);
                    };
                    const file = e.target.files![0];
                    if (!file) return;
                    fr.readAsDataURL(file);
                    setTimeout(() => {
                      const url =
                        "https://api.cloudinary.com/v1_1/KlydoClock/image/upload";
                      const formData = new FormData();
                      formData.append("file", file);
                      formData.append("folder", "profile");
                      formData.append("upload_preset", "Bezalel_preset");
                      return fetch(url, {
                        method: "POST",
                        body: formData,
                      })
                        .then((response) => {
                          return response.json();
                        })
                        .then((res) => {
                          const img = res.secure_url;
                          if (img) {
                            setImageUrl(img);
                          }
                        })
                        .catch((e) => {
                          console.log("e:", e);
                          let allErr = error;
                          allErr.pic = e.toLocaleString();
                          setError(allErr);
                        });
                    }, 0);
                  }}
                  type="file"
                  style={{ width: 100 + "%", height: 100 + "%", opacity: 0 }}
                />
              </div>
            </div>
          )}

          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            ref={formRef}
            id="newUserForm"
          >
            <Row>
              <Col sm="6">
                <Form.Group controlId="formAddName" style={{ display: "flex" }}>
                  <Form.Label style={{ padding: 5 }}>
                    <b>Name</b>
                  </Form.Label>
                  <Col>
                    {role === "clock" ? (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: "0.5rem",
                        }}
                      >
                        <p>Clock</p>
                      </div>
                    ) : (
                      <>
                        <Form.Control
                          isInvalid={error.name !== ""}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="name"
                          placeholder="Enter name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {error.name}
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group
                  controlId="formAddRole"
                  style={{ display: "flex", height: "40px" }}
                >
                  <Form.Label style={{ padding: 5 }}>
                    <b>Role</b>
                  </Form.Label>
                  <Form.Select
                    value={role}
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                  >
                    {roles.map((s) => (
                      <option key={s} value={s}>
                        {s}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Group
                controlId="formAddEmail"
                style={{
                  display: "flex",
                  paddingTop: 20,
                  width: role === "clock" ? "50%" : "100%",
                }}
              >
                <Form.Label style={{ width: "140px", padding: 5 }}>
                  <b>Email address</b>
                </Form.Label>
                <Col>
                  <Form.Control
                    isInvalid={error.email !== ""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type={role === "clock" ? "name" : "email"}
                    placeholder="Enter email"
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ alignSelf: "flex-end" }}
                  >
                    {error.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {role === "clock" && <p>@clocks.com</p>}
            </div>
            {role === "clock" ? (
              <b>Auto password</b>
            ) : (
              <Form.Group
                controlId="formAddPassword"
                style={{ display: "flex", paddingTop: 20 }}
              >
                <Form.Label style={{ padding: 5 }}>
                  <b>Password</b>
                </Form.Label>
                <Col>
                  <Form.Control
                    isInvalid={error.password !== ""}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                    required
                    disabled={role === "clock"}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ alignSelf: "end" }}
                  >
                    {error.password}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            )}
            {role === "editor" && (
              <SocialNetworkEdit
                list={socialNetworkList}
                onChange={(list: SocialNetwork[]) => setSocialNetworkList(list)}
              ></SocialNetworkEdit>
            )}
            {role === "editor" ? (
              <>
                <Form.Group
                  controlId="formAddCountry"
                  style={{ display: "flex", height: "40px", marginTop: 20 }}
                >
                  <Form.Label style={{ padding: 5 }}>
                    <b>Country</b>
                  </Form.Label>
                  <Form.Select
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                    {countrys.map((s: any) => (
                      <option key={s.value} value={s.value}>
                        {s.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {country === "US" ? (
                  <>
                    <Form.Group
                      controlId="formAddUsState"
                      style={{ display: "flex", height: "40px", marginTop: 20 }}
                    >
                      <Form.Label style={{ padding: 5 }}>
                        <b>State</b>
                      </Form.Label>
                      <Form.Select
                        value={state}
                        onChange={(e) => {
                          setState(e.target.value);
                        }}
                      >
                        {statesList.map((s: any) => (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </>
                ) : (
                  <></>
                )}
                <Form.Group
                  controlId="formAddDescription"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Label style={{ padding: 5 }}>
                      <b>Description</b>
                    </Form.Label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Form.Control
                        as="textarea"
                        style={{ height: "160px" }}
                        value={description}
                        isInvalid={error.description !== ""}
                        maxLength={descriptionMaxLength}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setCountChar(
                            descriptionMaxLength - e.target.value.length,
                          );
                        }}
                      ></Form.Control>
                      {countChar === undefined ? (
                        <></>
                      ) : (
                        <p
                          style={{
                            color: countChar <= 10 ? "red" : "green",
                            fontSize: "10px",
                          }}
                        >
                          characters left: {countChar}
                        </p>
                      )}
                    </div>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {error.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            ) : (
              <></>
            )}
            <Form.Group style={{ display: "flex", justifyContent: "right" }}>
              <Button title="add" type="submit">
                <>
                  {loading ? <Spinner animation="border" size="sm" /> : <></>}
                  add
                </>
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={!!toRemove || warning !== ""} onHide={closeConfirm}>
        <Modal.Header style={{ justifyContent: "right" }}>
          <i className="nc-icon nc-simple-remove" onClick={closeConfirm}></i>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you want to remove this user?</h4>
          <h6>{toRemove?.uid}</h6>
          {warning ? (
            <p style={{ color: "red" }}>{warning}</p>
          ) : (
            <>
              <Button style={{ width: 180, margin: 10 }} onClick={closeConfirm}>
                No
              </Button>
              <ButtonLoader
                style={{ width: 180, margin: 10 }}
                title="Yes"
                onClick={() => {
                  return new Promise<void>((v, x) => {
                    firebaseService
                      .removeUser(toRemove!!)
                      .then(() => {
                        closeConfirm();
                        v();
                      })
                      .catch((e) => {
                        setWarning(e.message || "");
                        x();
                      });
                  });
                }}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Users;
