const adjectives = [
  "afraid",
  "ancient",
  "angry",
  "average",
  "bad",
  "big",
  "bitter",
  "black",
  "blue",
  "brave",
  "breezy",
  "bright",
  "brown",
  "calm",
  "chatty",
  "chilly",
  "clever",
  "cold",
  "cowardly",
  "cuddly",
  "curly",
  "curvy",
  "dangerous",
  "dry",
  "dull",
  "empty",
  "evil",
  "fast",
  "fat",
  "fluffy",
  "foolish",
  "fresh",
  "friendly",
  "funny",
  "fuzzy",
  "gentle",
  "giant",
  "good",
  "great",
  "green",
  "grumpy",
  "happy",
  "hard",
  "heavy",
  "helpless",
  "honest",
  "horrible",
  "hot",
  "hungry",
  "itchy",
  "jolly",
  "kind",
  "lazy",
  "light",
  "little",
  "loud",
  "lovely",
  "lucky",
  "massive",
  "mean",
  "mighty",
  "modern",
  "moody",
  "nasty",
  "neat",
  "nervous",
  "new",
  "nice",
  "odd",
  "old",
  "orange",
  "ordinary",
  "perfect",
  "pink",
  "plastic",
  "polite",
  "popular",
  "pretty",
  "proud",
  "purple",
  "quick",
  "quiet",
  "rare",
  "red",
  "rotten",
  "rude",
  "selfish",
  "serious",
  "shaggy",
  "sharp",
  "short",
  "shy",
  "silent",
  "silly",
  "slimy",
  "slippery",
  "smart",
  "smooth",
  "soft",
  "sour",
  "spicy",
  "splendid",
  "spotty",
  "stale",
  "strange",
  "strong",
  "stupid",
  "sweet",
  "swift",
  "tall",
  "tame",
  "tasty",
  "tender",
  "terrible",
  "thin",
  "tidy",
  "tiny",
  "tough",
  "tricky",
  "ugly",
  "unlucky",
  "warm",
  "weak",
  "wet",
  "white",
  "wicked",
  "wise",
  "witty",
  "wonderful",
  "yellow",
  "young",
];
const adjectivesSize = BigInt(adjectives.length);
const animals = [
  "ape",
  "baboon",
  "badger",
  "bat",
  "bear",
  "bird",
  "bobcat",
  "bulldog",
  "bullfrog",
  "cat",
  "catfish",
  "cheetah",
  "chicken",
  "chipmunk",
  "cobra",
  "cougar",
  "cow",
  "crab",
  "deer",
  "dingo",
  "dodo",
  "dog",
  "dolphin",
  "donkey",
  "dragon",
  "dragonfly",
  "duck",
  "eagle",
  "earwig",
  "eel",
  "elephant",
  "emu",
  "falcon",
  "fireant",
  "firefox",
  "fish",
  "fly",
  "fox",
  "frog",
  "gecko",
  "goat",
  "goose",
  "grasshopper",
  "horse",
  "hound",
  "husky",
  "impala",
  "insect",
  "jellyfish",
  "kangaroo",
  "ladybug",
  "liger",
  "lion",
  "lionfish",
  "lizard",
  "mayfly",
  "mole",
  "monkey",
  "moose",
  "moth",
  "mouse",
  "mule",
  "newt",
  "octopus",
  "otter",
  "owl",
  "panda",
  "panther",
  "parrot",
  "penguin",
  "pig",
  "puma",
  "pug",
  "quail",
  "rabbit",
  "rat",
  "rattlesnake",
  "robin",
  "seahorse",
  "sheep",
  "shrimp",
  "skunk",
  "sloth",
  "snail",
  "snake",
  "squid",
  "starfish",
  "stingray",
  "swan",
  "termite",
  "tiger",
  "treefrog",
  "turkey",
  "turtle",
  "vampirebat",
  "walrus",
  "warthog",
  "wasp",
  "wolverine",
  "wombat",
  "yak",
  "zebra",
];
const animalsSize = BigInt(animals.length);
const nums = [
  0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
  99, 100,
];
const numsSize = BigInt(nums.length);
const bigint1 = BigInt(1);
const ALPHA =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
const s64 = BigInt(64);
function b642int(v: string) {
  let result = BigInt(0);
  for (var i = 0; i < v.length; i++) {
    result *= s64;
    result += BigInt(ALPHA.indexOf(v[i]));
  }

  return result;
}

function friendlyFromHex(id: string | undefined) {
  if (!id) return "missing";
  id = id.replaceAll("-", "");
  const num = BigInt("0x" + id);
  return `${adjectives[Number(num % adjectivesSize)]}-${animals[Number(num % animalsSize)]}-${nums[Number(num % numsSize)]}`;
}
function friendlyIdFromb64(id: string) {
  try {
    const num = b642int(id);
    return `${adjectives[Number(num % adjectivesSize)]}-${animals[Number(num % animalsSize)]}-${nums[Number(num % numsSize)]}`;
  } catch (e) {
    return "not valid id";
  }
}
const primes = [
  7, 11, 13, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89,
  101, 103, 107, 109, 113, 127, 137, 139, 149, 151, 157, 163, 167, 173, 179,
  181, 191, 193, 197, 199, 211, 223, 227,
];
function friendlyIdFromString(id: string) {
  id = id.substring(5);
  let num = bigint1;
  for (let i = 0; i < id.length; i++) {
    const index = id[i].toUpperCase().charCodeAt(0) - 48;
    num *= BigInt(primes[index]);
    num += BigInt(index);
  }
  return `${adjectives[Number(num % adjectivesSize)]}-${animals[Number(num % animalsSize)]}-${nums[Number(num % numsSize)]}`;
}
const obj = {
  friendlyIdFromb64,
  friendlyFromHex,
  friendlyIdFromString,
};
export default obj;
