import axios from "axios";
import { baseUrl, getToken } from "./baseRequest";

export type VersionUpdate = {
  version: number;
  update: "public" | "beta";
};

export type AdminApiClient = {
  publishVersion: (versionUpdate: VersionUpdate) => Promise<void>;
};

const adminApiClient: AdminApiClient = {
  publishVersion: async (versionUpdate) => {
    const token = await getToken();
    if (!token) throw new Error("No token found");
    return axios
      .post(`${baseUrl}/admin/publish-version`, versionUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  },
};
export default adminApiClient;
