// react-bootstrap components
import {
  Button,
  Container,
  Modal,
  Spinner,
  Card,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { useEffect, useState } from "react";
import firebaseService from "firebase_service/firebaseService";
import ReactFlagsSelect from "react-flags-select";
import ReactCountryFlag from "react-country-flag";
import EditableProfile from "components/Widgets/EditableUser";
import clock_profile from "assets/img/klydoclock_profile.png";
import { FaEdit } from "react-icons/fa";
import {
  isVideo,
  labelValueState,
  myColor,
  resizeCloudinary,
  secondsToTime,
  statesList,
  valueLabelState,
} from "../utils/util";
import Earth from "assets/img/Earth.png";
import anonymousPhoto from "assets/img/anonymous.png";
import { descriptionMaxLength } from "./Users";
import SocialNetworkEdit from "components/Widgets/SocialNetworkEdit";
import { Klydo, SocialNetwork, Tag, User, colType } from "Types";
import MsTable from "components/Widgets/Table";
import { NavLink } from "react-router-dom";
import bb from "../assets/img/Box_all.gif";

function UserModal(props: {
  row: User;
  setShowModal: (b: boolean) => void;
  klydos: Array<Klydo>;
}) {
  const setShowModal = props.setShowModal;
  const user = props.row;
  const [klydos, setKlydos] = useState<Array<Klydo>>();
  const [tags, setTags] = useState<Array<Tag>>();
  const [selectCountry, setSelectCountry] = useState(false);
  const [emailInFb, setEmailInFb] = useState(true);
  const [inspiration, setInspiration] = useState("");
  const [country, setCountry] = useState("EA");
  const [state, setState] = useState("AL");
  const [photoUrl, setPhotoUrl] = useState<any>(
    user.photoURL ?? anonymousPhoto,
  );
  const [loading, setLoading] = useState(false);
  const [hasKlydos, setHasKlydos] = useState(false);
  const [statsPub, setStatsPub] = useState(0);
  const [statsTime, setStatsTime] = useState(0);
  const [statsFavs, setStatsFavs] = useState(0);
  const [attachedClock, setAttachedClock] = useState("");
  const [socialNetworkList, setSocialNetworkList] = useState<
    Array<SocialNetwork>
  >([]);
  const [showArtistViewsModal, setShowArtistViewsModal] = useState(false);
  const [artistKlydoViewsData, setArtistKlydoViewsData] =
    useState<Array<any>>();

  useEffect(() => {
    setLoading(true);
    firebaseService.getUserKlydos(user.uid).then(setKlydos);
    firebaseService.getUserProfileFromFb(user.uid).then((p: any) => {
      if (!p.email) setEmailInFb(false);
      setCountry(p.country ?? "US");
      setState(p.state ?? "AL");
      setInspiration(p.description);
      if (p.hasKlydos) {
        setHasKlydos(true);
        setStatsPub(p.totalPublic);
        setStatsTime(p.totalTime);
        setStatsFavs(p.totalFavs);
      }
      if (p.clock) {
        setAttachedClock(p.clock);
      }
      if (p.socialMedia) {
        setSocialNetworkList(p.socialMedia);
      }
      setLoading(false);
    });
  }, [user.uid]);

  const tooltip = (klydoId: string) => (
    <Tooltip
      style={{ background: "transparent", border: "none", appearance: "none" }}
      id="tooltip"
    >
      {imgOrVideo(klydoId)}
    </Tooltip>
  );

  const imgOrVideo = (id: string) => {
    if (id === "9d371dd0-f44a-4d4d-8aeb-a927a788c978")
      return (
        <img
          style={{ borderRadius: 50 + "%" }}
          loading="lazy"
          height={96}
          width={96}
          src={bb}
          alt="klydo"
        />
      );
    const url = klydos?.find((d) => d.id === id)?.loopUrl;
    if (url) {
      if (isVideo(url) === "video")
        return (
          <video
            muted={true}
            style={{ borderRadius: 50 + "%" }}
            loop
            autoPlay
            height={96}
            width={96}
            src={url}
          />
        );
      if (isVideo(url) === "image")
        return (
          <img
            style={{ borderRadius: 50 + "%" }}
            loading="lazy"
            height={96}
            width={96}
            src={resizeCloudinary(url, 96)}
            alt="klydo"
          />
        );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>File type not supported</p>
      </div>
    );
  };

  const tableColumn: Array<colType> = [
    {
      sort: true,
      label: "Klydo Id",
      field: "klydoIdf",
    },
    {
      sort: true,
      label: "Device Id",
      field: "deviceId",
    },
    {
      sort: true,
      label: "Views",
      field: "views",
    },
    {
      size: 170,
      sort: true,
      label: "Average Display Time (mos:day:hrs:min:sec)",
      field: "avgDisplayTime",
    },
  ];
  const tableRows = (
    r: {
      deviceId: string;
      klydoId: string;
      klydo: Klydo;
      views: number;
      avgDisplayTime: number;
    },
    key: string,
  ) => {
    return {
      klydoIdf: (
        <OverlayTrigger placement="top" overlay={tooltip(r.klydoId)}>
          <NavLink
            to="/admin/klydos"
            onClick={() => {
              localStorage.setItem("klydossearch", r.klydo.idf);
            }}
          >
            {r.klydo.idf}
          </NavLink>
        </OverlayTrigger>
      ),
      deviceId: (
        <NavLink
          to="/admin/devices"
          onClick={() => {
            localStorage.setItem("devicessearch", r.deviceId);
          }}
        >
          {r.deviceId}
        </NavLink>
      ),
      avgDisplayTime: (
        <p style={{ textAlign: "center" }}>
          {secondsToTime(Math.round(r.avgDisplayTime))}
        </p>
      ),
      views: <p style={{ textAlign: "center" }}>{r.views}</p>,
      // autoSwitch: r.autoSwitch.toString(),
      // favorite: r.favorite.toString(),
      // collectionType: <p style={{textAlign:'center'}}>{r.collectionType}</p>,
      key: key,
    };
  };

  return (
    <>
      <Modal.Header style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}>
        <h4>{user.displayName}</h4>
        <i
          className="nc-icon nc-simple-remove"
          style={{ marginTop: 15 }}
          onClick={() => setShowModal(false)}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col sm={hasKlydos ? "6" : "12"}>
              <Card
                className="card-stats shadow"
                style={{ background: myColor, paddingBottom: 16 }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Profile
                </Card.Header>
                <hr></hr>
                <Card.Body>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            width: 200,
                            height: 200,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ position: "absolute" }}>
                            <img
                              referrerPolicy="no-referrer"
                              style={{ borderRadius: 50 + "%" }}
                              loading="eager"
                              height={150}
                              width={150}
                              alt="profile"
                              src={
                                user.role === "clock"
                                  ? clock_profile
                                  : photoUrl ||
                                    "https://react.semantic-ui.com/images/wireframe/square-image.png"
                              }
                            />
                          </span>
                          <div style={{ height: 15, width: 15 }}></div>
                          <input
                            id="user-photo"
                            onChange={(e) => {
                              const fr = new FileReader();
                              const fr2 = new FileReader();
                              fr.onload = function () {
                                setPhotoUrl(fr.result);
                              };
                              fr2.onload = function () {
                                setTimeout(() => {
                                  const url =
                                    "https://api.cloudinary.com/v1_1/KlydoClock/image/upload";
                                  const formData = new FormData();
                                  formData.append("file", file);
                                  formData.append("folder", "profile");
                                  formData.append("upload_preset", "profiles");
                                  return fetch(url, {
                                    method: "POST",
                                    body: formData,
                                  })
                                    .then((response) => {
                                      return response.json();
                                    })
                                    .then((res) => {
                                      const img = res.secure_url;
                                      if (img) {
                                        if (
                                          file.type === "image/gif" ||
                                          (file.type === "image/webp" &&
                                            fr2
                                              .result!.toString()
                                              .indexOf("ANMF") !== -1)
                                        ) {
                                          firebaseService
                                            .updateUserProfile(
                                              {
                                                fb: "animated",
                                                user: null,
                                                value: "delete",
                                              },
                                              user.uid,
                                            )
                                            .then(() => {
                                              firebaseService.updateUserAnimatedPhoto(
                                                img,
                                                {
                                                  fb: "photoUrl",
                                                  user: "photoURL",
                                                  value: img,
                                                },
                                                user.uid,
                                              );
                                            });
                                        } else {
                                          firebaseService.updateUserProfile(
                                            {
                                              fb: "animated",
                                              user: null,
                                              value: "delete",
                                            },
                                            user.uid,
                                          );
                                          firebaseService.updateUserProfile(
                                            {
                                              fb: "photoUrl",
                                              user: "photoURL",
                                              value: img,
                                            },
                                            user.uid,
                                          );
                                        }
                                      }
                                    });
                                }, 0);
                              };
                              const file = e.target.files![0];
                              if (!file) return;
                              fr.readAsDataURL(file);
                              fr2.readAsText(file);
                            }}
                            type="file"
                            style={{
                              width: 100 + "%",
                              height: 100 + "%",
                              opacity: 0,
                            }}
                          />
                        </div>
                        {user.role !== "clock" ? (
                          <>
                            <SocialNetworkEdit
                              list={socialNetworkList}
                              onChange={(list) => {
                                firebaseService.updateUserSocialMedia(
                                  user.uid,
                                  list,
                                );
                              }}
                            ></SocialNetworkEdit>
                            {selectCountry ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ width: 300 }}>
                                  <ReactFlagsSelect
                                    selected={country}
                                    searchable
                                    fullWidth={true}
                                    onSelect={(code) => {
                                      setCountry(code);
                                      setSelectCountry(false);
                                      firebaseService.updateUserProfile(
                                        {
                                          fb: "country",
                                          user: null,
                                          value: code,
                                        },
                                        user.uid,
                                      );
                                    }}
                                  />
                                </div>
                                <i
                                  className="nc-icon nc-simple-remove"
                                  style={{ marginTop: 15 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectCountry(false);
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  position: "relative",
                                  width: 55,
                                  height: 55,
                                }}
                              >
                                {country === "EA" ? (
                                  <img
                                    src={Earth}
                                    title="Earth"
                                    style={{
                                      position: "relative",
                                      width: 55,
                                      height: 55,
                                    }}
                                    alt="Earth"
                                  />
                                ) : (
                                  <ReactCountryFlag
                                    svg
                                    countryCode={country}
                                    style={{ width: 55, height: 55 }}
                                  />
                                )}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 33,
                                    left: 47,
                                  }}
                                >
                                  <FaEdit
                                    style={{
                                      backgroundColor: "white",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectCountry(true);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            {country === "US" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <EditableProfile
                                  type="select"
                                  default={valueLabelState(state)}
                                  onChange={(v) => {
                                    firebaseService.updateUserProfile(
                                      {
                                        fb: "state",
                                        user: null,
                                        value: labelValueState(v),
                                      },
                                      user.uid,
                                    );
                                  }}
                                  options={statesList.map((v) => v.label)}
                                  style={{ fontSize: "2em" }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "50%",
                              }}
                            >
                              <EditableProfile
                                type={"short"}
                                default={user.displayName}
                                style={{ fontSize: "2.9em" }}
                                onChange={(v) =>
                                  firebaseService.updateUserProfile(
                                    {
                                      fb: "name",
                                      user: "displayName",
                                      value: v,
                                    },
                                    user.uid,
                                  )
                                }
                              />
                              {user.password ? (
                                <EditableProfile
                                  type={"short"}
                                  default={user.email}
                                  style={{ fontSize: "2.5em" }}
                                  onChange={(v) => {
                                    firebaseService.updateUserProfile(
                                      {
                                        fb: emailInFb ? "email" : null,
                                        user: "email",
                                        value: v,
                                      },
                                      user.uid,
                                    );
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignContent: "center",
                                    fontSize: "2.5em",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 0.8 + "em",
                                        margin: 0,
                                        marginRight: 4 + "px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {user.email}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {attachedClock ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignContent: "center",
                                    fontSize: "0.8em",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "0.8em",
                                        margin: 0,
                                        marginRight: 4 + "px",
                                        textAlign: "center",
                                      }}
                                    >
                                      clock: {attachedClock}
                                    </p>
                                    <i
                                      className="nc-icon nc-simple-remove"
                                      onClick={() =>
                                        firebaseService.removeAttachedClock(
                                          user.uid,
                                        )
                                      }
                                    ></i>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <EditableProfile
                                active={!inspiration}
                                type={"long"}
                                maxLength={descriptionMaxLength}
                                style={{ fontSize: "2em" }}
                                default={inspiration}
                                onChange={(v) => {
                                  firebaseService.updateUserProfile(
                                    { fb: "description", user: null, value: v },
                                    user.uid,
                                  );
                                }}
                              />
                              <EditableProfile
                                type="select"
                                default={user.role || "editor"}
                                active={!user.role}
                                onChange={(v) => {
                                  firebaseService.updateUserRole(user.uid, v);
                                }}
                                options={["editor", "admin", "viewer"]}
                                style={{ fontSize: "2em" }}
                              />
                            </div>
                          </>
                        ) : (
                          <p>Clock</p>
                        )}
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {hasKlydos && (
              <Col lg="6" sm="6">
                <Card
                  className="card-stats shadow"
                  style={{ background: myColor, height: 95 + "%" }}
                >
                  <Card.Header
                    as="h4"
                    style={{ marginTop: 0, background: myColor }}
                  >
                    Klydo stats
                  </Card.Header>
                  <hr></hr>
                  <Card.Body>
                    <h4>
                      <b>Number of public klydos: </b>
                      {statsPub}
                    </h4>
                    <h4>
                      <b>Total view time: </b>
                      {statsTime}
                    </h4>
                    <h4>
                      <b>Total likes: </b>
                      {statsFavs}
                    </h4>
                    {statsPub > 0 && (
                      <Button
                        style={{ display: "inline-block", marginTop: "15px" }}
                        onClick={() => {
                          let artistKlydoList = klydos
                            ?.filter((k) => k.creator === user.uid)
                            .map((k) => k.id);
                          setShowArtistViewsModal(true);
                          firebaseService
                            .getKlydoAnalyticsByKlydo(artistKlydoList)
                            .then((result) => {
                              setArtistKlydoViewsData(
                                result.map((res) => ({
                                  klydoId: res.klydo_id,
                                  klydo: klydos!.find(
                                    (k) => res.klydo_id === k.id,
                                  ),
                                  deviceId: res.clock,
                                  views: res.views,
                                  avgDisplayTime: res.average_display_time,
                                })),
                              );
                            })
                            .catch(console.log);
                        }}
                      >
                        artist klydo views statistics
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </Modal.Body>
      <div className="modal-footer justify-content-end">
        <Button
          className="btn-simple right"
          type="button"
          variant="link"
          onClick={() => setShowModal(false)}
        >
          OK
        </Button>
      </div>
      <Modal show={false}></Modal>

      {showArtistViewsModal && (
        <Modal
          dialogClassName="custom-dialog-style"
          contentClassName="custom-modal-style"
          show={showArtistViewsModal}
          onHide={() => setShowArtistViewsModal(false)}
        >
          <Modal.Header
            style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}
          >
            <h4>User's Klydo Viewing Stats</h4>
            <i
              className="nc-icon nc-simple-remove"
              style={{ marginTop: 15 }}
              onClick={() => setShowArtistViewsModal(false)}
            ></i>
          </Modal.Header>

          <MsTable
            id={"artistKlydosViews"}
            rowBuild={tableRows}
            search={(row: any, val: string) => {
              return (
                row.deviceId?.includes(val),
                row.klydo?.tags?.find((t: string) => t.toLowerCase() === val) ||
                  row.klydo?.idf.includes(val) ||
                  row.klydo?.id.includes(val) ||
                  row.klydo?.author?.toLowerCase().includes(val) ||
                  row.klydo?.name.toLowerCase().includes(val)
              );
            }}
            initSort={"clockTime"}
            data={artistKlydoViewsData || Array<any>()}
            cols={tableColumn}
            title=""
          />
          {!artistKlydoViewsData && (
            <div id="fuse-splash-screen-version2">
              <div className="center">
                <div className="spinner-wrapper">
                  <div className="spinner">
                    <div className="inner">
                      <div className="gap" />
                      <div className="left">
                        <div className="half-circle" />
                      </div>
                      <div className="right">
                        <div className="half-circle" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

export default UserModal;
