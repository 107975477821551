import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaEdit, FaSave } from "react-icons/fa";

const EditableProfile = (props: {
  type: "long" | "short" | "select";
  default: string;
  onChange: (value: string) => void;
  style: React.CSSProperties;
  active?: boolean;
  options?: string[];
  maxLength?: number;
}) => {
  const [active, setActive] = useState(props.active ? props.active : false);
  const [newVal, setNewVal] = useState<string>();
  const [val, setVal] = useState<string>("");
  const [warning, setWarning] = useState("");
  const [countChar, setCountChar] = useState<number>();
  const checkLengthVal = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setVal(e.target.value);
    props.maxLength && setCountChar(props.maxLength - e.target.value.length);
    setWarning("");
  };

  useEffect(() => {
    props.maxLength && val && val.length > props.maxLength
      ? setWarning(`The maximum length is ${props.maxLength} charecters`)
      : setWarning("");
  }, [val, props.maxLength]);
  useEffect(() => {
    active ? setVal(newVal ? newVal : props.default) : setVal("");
  }, [active, newVal, props.default]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        fontSize: "1.4em",
        ...props.style,
      }}
    >
      {active ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {props.type === "long" ? (
              <textarea
                autoFocus
                onChange={checkLengthVal}
                defaultValue={newVal ? newVal : props.default}
              />
            ) : props.type === "select" ? (
              <select
                autoFocus
                onChange={(e) => {
                  setVal(e.target.value);
                }}
                defaultValue={newVal ? newVal : props.default}
              >
                {props.options?.map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>
            ) : (
              <Form.Control
                autoFocus
                style={{
                  outline: 0,
                  border: "unset",
                  borderBottom: "solid 1px",
                  padding: 0,
                }}
                onChange={checkLengthVal}
                defaultValue={newVal ? newVal : props.default}
              />
            )}
            <FaSave
              size={parseFloat(props.style.fontSize!.valueOf().toString()) * 7}
              style={{ marginLeft: 10 }}
              onClick={(e) => {
                e.stopPropagation();
                if (props.maxLength && val && val.length > props.maxLength) {
                  setWarning("Can not save - to many charecters");
                  return;
                }
                props.onChange(val);
                setActive(false);
                setNewVal(val);
              }}
            />
          </div>
          {countChar === undefined ? (
            <></>
          ) : countChar < 0 ? (
            <p style={{ color: "red", fontSize: 10 }}>
              To many characters: {countChar}
            </p>
          ) : (
            <p style={{ color: "green", fontSize: 10 }}>
              You have {countChar} characters left
            </p>
          )}
          {warning && <p style={{ color: "red", fontSize: 10 }}>{warning}</p>}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: 0.8 + "em",
              margin: 0,
              marginRight: 10 + "px",
              textAlign: "center",
            }}
          >
            {newVal ? newVal : props.default}
            <FaEdit
              size={parseFloat(props.style.fontSize!.valueOf().toString()) * 7}
              style={{ marginLeft: 10 }}
              onClick={(e: any) => {
                e.stopPropagation();
                setActive(true);
              }}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default EditableProfile;
