import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";

const ButtonLoader = (props: any) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const setE = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };
  return (
    <Button
      {...props}
      onClick={
        loading
          ? undefined
          : () => {
              setLoading(true);
              props
                .onClick()
                .catch(setE)
                .finally(() => setLoading(false));
            }
      }
    >
      <>
        {loading && <Spinner animation="border" size="sm" />}
        {error && <i className="nc-icon nc-simple-remove"></i>}
        {props.title}
      </>
    </Button>
  );
};
export default ButtonLoader;
