import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const SimpleTimePicker = (props: {
  title: string;
  onChange: (e: any) => void;
  default: string;
}) => {
  const parseTime = () => {
    const parts = props.default.split(":");
    return `${parts[0].padStart(2, "0")}:${parts[1].padStart(2, "0")}`;
  };
  const [time, setTime] = useState(() => parseTime());

  return (
    <>
      <Form.Group as={Row} className="justify-content-left">
        <Col md="auto">
          <Form.Label as="h5" style={{ paddingTop: 12 }}>
            <b>{props.title}</b>
          </Form.Label>
        </Col>
        <Col md="auto">
          <Form.Control
            value={time}
            type="time"
            onChange={(e) => {
              props.onChange(e);
              setTime(e.target.value);
            }}
          />
        </Col>
      </Form.Group>
    </>
  );
};

export default SimpleTimePicker;
