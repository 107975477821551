import {
  Alert,
  Button,
  Container,
  Modal,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import firebaseService from "firebase_service/firebaseService";
import { resizeCloudinary, isVideo, myColor } from "../utils/util";
import { Klydo, ScheduledTask } from "Types";
import { useState } from "react";
import ButtonLoader from "components/Widgets/ButtonLoader";
import placeholderImage from "assets/img/placeholderImage.png";
import KlydoModal from "./KlydoModal";

function ScheduledTaskModal({
  open,
  handleClose,
  task,
}: {
  open: boolean;
  handleClose: (b: boolean) => void;
  task: ScheduledTask;
}) {
  const [over, setOver] = useState("");
  const [showInnerModal, setShowInnerModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Klydo>();
  const [alert, setAlert] = useState<string>();
  const [add, setAdd] = useState(true);
  const [showKlydoModal, setShowKlydoModal] = useState(false);
  const [selectedKlydo, setSelectedKlydo] = useState<Klydo>();
  return (
    <>
      <Modal show={open} onHide={() => handleClose(false)}>
        <Modal.Header style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}>
          <h4>Scheduled Task Info</h4>
          {
            <i
              className="nc-icon nc-simple-remove"
              style={{ marginTop: 15 }}
              onClick={() => handleClose(false)}
            ></i>
          }
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                <Card
                  className="card-stats shadow"
                  style={{ background: myColor }}
                >
                  <Card.Header
                    as="h4"
                    style={{ marginTop: 0, background: myColor }}
                  >
                    <b>Date Scheduled</b> {task.date.toLocaleDateString()}
                  </Card.Header>
                  <hr style={{ margin: 0 }} />
                </Card>
                <Row>
                  <Col>
                    <Card
                      className="card-stats shadow"
                      style={{ background: myColor }}
                    >
                      {(task.add as Klydo[]).filter((k) => !k.featured).length >
                        0 && (
                        <Card.Header
                          as="h4"
                          style={{ marginTop: 0, background: myColor }}
                        >
                          {task.add.length} Klydos To Add
                        </Card.Header>
                      )}
                      <Card.Body>
                        <Row
                          className="pr-1"
                          md="12"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {(task.add as Klydo[])
                            .filter((k) => !k.featured)
                            .map((item, index) => (
                              <Row
                                title={item.name + " : " + item.idf}
                                onMouseOver={(i) => setOver(item.id)}
                                onMouseOut={(i) => setOver("")}
                                key={index}
                                onClick={() => {
                                  setSelectedKlydo(item);
                                  setShowKlydoModal(true);
                                }}
                                style={{
                                  flex: "0.12 1",
                                  position: "relative",
                                  padding: "15px",
                                  marginRight: "0.5",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: 48,
                                    height: 48,
                                    position: "relative",
                                    overflow: "hidden",
                                    marginRight: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      position: "absolute",
                                      top: item.crop?.top || 0 + "%",
                                      left: item.crop?.left || 0 + "%",
                                      width: `${item.crop?.zoom || 100}%`,
                                      aspectRatio: "1/1",
                                    }}
                                  >
                                    {isVideo(item.loopUrl) === "image" ? (
                                      <img
                                        loading="lazy"
                                        alt="gif"
                                        src={resizeCloudinary(item.loopUrl, 48)}
                                        style={{
                                          width: "auto",
                                          height: "100%",
                                          objectFit: "cover",
                                          background: `url(${placeholderImage}) no-repeat scroll 0 0`,
                                        }}
                                      />
                                    ) : (
                                      <video
                                        poster={placeholderImage}
                                        muted={true}
                                        src={resizeCloudinary(item.loopUrl, 48)}
                                        loop
                                        autoPlay
                                        style={{
                                          width: "auto",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                <i
                                  className="nc-icon nc-simple-add"
                                  title="Remove"
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setShowInnerModal(true);
                                    setAdd(true);
                                  }}
                                  style={{
                                    display:
                                      over === item.id ? "block" : "none",
                                    justifyContent: "flex-end",
                                    position: "absolute",
                                    fontSize: "0.7rem",
                                    fontWeight: 800,
                                    width: "12px",
                                    height: "12px",
                                    top: "5px",
                                    right: "19px",
                                    borderRadius: "50%",
                                    color: "black",
                                    rotate: "45deg",
                                  }}
                                ></i>
                              </Row>
                            ))}
                        </Row>
                      </Card.Body>
                      {(task.add as Klydo[]).filter((k) => k.featured).length >
                        0 && (
                        <Card.Header
                          as="h4"
                          style={{ marginTop: 0, background: myColor }}
                        >
                          Featured
                        </Card.Header>
                      )}
                      <Card.Body>
                        <Row
                          className="pr-1"
                          md="12"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {(task.add as Klydo[])
                            .filter((k) => k.featured)
                            .map((item, index) => (
                              <Row
                                title={item.name + " : " + item.idf}
                                onMouseOver={(i) => setOver(item.id)}
                                onMouseOut={(i) => setOver("")}
                                key={index}
                                style={{
                                  flex: "0.12 1",
                                  position: "relative",
                                  padding: "15px",
                                  marginRight: "0.5",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: 48,
                                    height: 48,
                                    position: "relative",
                                    overflow: "hidden",
                                    marginRight: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      position: "absolute",
                                      top: item.crop?.top || 0 + "%",
                                      left: item.crop?.left || 0 + "%",
                                      width: `${item.crop?.zoom || 100}%`,
                                      aspectRatio: "1/1",
                                    }}
                                  >
                                    {isVideo(item.loopUrl) === "image" ? (
                                      <img
                                        loading="lazy"
                                        alt="gif"
                                        src={resizeCloudinary(item.loopUrl, 48)}
                                        style={{
                                          width: "auto",
                                          height: "100%",
                                          objectFit: "cover",
                                          background: `url(${placeholderImage}) no-repeat scroll 0 0`,
                                        }}
                                      />
                                    ) : (
                                      <video
                                        poster={placeholderImage}
                                        muted={true}
                                        src={resizeCloudinary(item.loopUrl, 48)}
                                        loop
                                        autoPlay
                                        style={{
                                          width: "auto",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                <i
                                  className="nc-icon nc-simple-add"
                                  title="Remove"
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setShowInnerModal(true);
                                    setAdd(true);
                                  }}
                                  style={{
                                    display:
                                      over === item.id ? "block" : "none",
                                    justifyContent: "flex-end",
                                    position: "absolute",
                                    fontSize: "0.7rem",
                                    fontWeight: 800,
                                    width: "12px",
                                    height: "12px",
                                    top: "5px",
                                    right: "19px",
                                    borderRadius: "50%",
                                    color: "black",
                                    rotate: "45deg",
                                  }}
                                ></i>
                              </Row>
                            ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      className="card-stats shadow"
                      style={{ background: myColor }}
                    >
                      <Card.Header
                        as="h4"
                        style={{ marginTop: 0, background: myColor }}
                      >
                        {task.remove.length} Klydos To Remove
                      </Card.Header>
                      <Card.Body>
                        <Row
                          className="pr-1"
                          md="12"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {(task.remove as Klydo[]).map((item, index) => (
                            <Row
                              title={item.name + " : " + item.idf}
                              onMouseOver={(i) => setOver(item.id)}
                              onMouseOut={(i) => setOver("")}
                              key={index}
                              style={{
                                flex: "0.12 1",
                                position: "relative",
                                padding: "15px",
                                marginRight: "0.5",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "50%",
                                  width: 48,
                                  height: 48,
                                  position: "relative",
                                  overflow: "hidden",
                                  marginRight: "6px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "absolute",
                                    top: item.crop?.top || 0 + "%",
                                    left: item.crop?.left || 0 + "%",
                                    width: `${item.crop?.zoom || 100}%`,
                                    aspectRatio: "1/1",
                                  }}
                                >
                                  {isVideo(item.loopUrl) === "image" ? (
                                    <img
                                      loading="lazy"
                                      alt="gif"
                                      src={resizeCloudinary(item.loopUrl, 48)}
                                      style={{
                                        width: "auto",
                                        height: "100%",
                                        objectFit: "cover",
                                        background: `url(${placeholderImage}) no-repeat scroll 0 0`,
                                      }}
                                    />
                                  ) : (
                                    <video
                                      poster={placeholderImage}
                                      muted={true}
                                      src={resizeCloudinary(item.loopUrl, 48)}
                                      loop
                                      autoPlay
                                      style={{
                                        width: "auto",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <i
                                className="nc-icon nc-simple-add"
                                title="Remove"
                                onClick={() => {
                                  setSelectedItem(item);
                                  setShowInnerModal(true);
                                  setAdd(false);
                                }}
                                style={{
                                  display: over === item.id ? "block" : "none",
                                  justifyContent: "flex-end",
                                  position: "absolute",
                                  fontSize: "0.7rem",
                                  fontWeight: 800,
                                  width: "12px",
                                  height: "12px",
                                  top: "5px",
                                  right: "19px",
                                  borderRadius: "50%",
                                  color: "black",
                                  rotate: "45deg",
                                }}
                              ></i>
                            </Row>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        {showKlydoModal && selectedKlydo && !showInnerModal && (
          <Modal
            className="modal-big modal-primary"
            show={showKlydoModal}
            onHide={() => setShowKlydoModal(false)}
          >
            <KlydoModal row={selectedKlydo} setShowModal={setShowKlydoModal} />
          </Modal>
        )}
        {selectedItem && showInnerModal && (
          <Modal
            className="modal-medium"
            show={showInnerModal}
            onHide={() => setShowInnerModal(false)}
          >
            <Modal.Body>
              <Row style={{ margin: 25 }}>
                <h4 style={{ textAlign: "center" }}>
                  Are you sure you want to remove {selectedItem.idf}{" "}
                  {
                    <div
                      style={{
                        display: "inline-flex",
                        borderRadius: "50%",
                        width: 35,
                        height: 35,
                        position: "relative",
                        overflow: "hidden",
                        marginRight: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          top: selectedItem.crop?.top || 0 + "%",
                          left: selectedItem.crop?.left || 0 + "%",
                          width: `${selectedItem.crop?.zoom || 100}%`,
                          aspectRatio: "1/1",
                        }}
                      >
                        {isVideo(selectedItem.loopUrl) === "image" ? (
                          <img
                            loading="lazy"
                            alt="gif"
                            src={resizeCloudinary(selectedItem.loopUrl, 48)}
                            style={{
                              width: "auto",
                              height: "100%",
                              objectFit: "cover",
                              background: `url(${placeholderImage}) no-repeat scroll 0 0`,
                            }}
                          />
                        ) : (
                          <video
                            poster={placeholderImage}
                            muted={true}
                            src={resizeCloudinary(selectedItem.loopUrl, 48)}
                            loop
                            autoPlay
                            style={{
                              width: "auto",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>{" "}
                    </div>
                  }{" "}
                  from task?
                </h4>
              </Row>
              <Row className="justify-content-between">
                <Col sm="5">
                  <Button
                    className="w-100 h-100"
                    onClick={() => {
                      setShowInnerModal(false);
                      setShowKlydoModal(false);
                    }}
                  >
                    No
                  </Button>
                </Col>
                <Col sm="5">
                  <ButtonLoader
                    title="YES"
                    className="w-100 h-100"
                    onClick={() => {
                      return new Promise<void>((v, x) => {
                        firebaseService
                          .removeKlydoFromTask(selectedItem.id, task.date, add)
                          .then(() => {
                            setShowInnerModal(false);
                            setShowKlydoModal(false);
                            setSelectedKlydo(undefined);
                            setSelectedItem(undefined);
                            if (add) {
                              task.add = (task.add as Klydo[]).filter(
                                (k) => !(k.id === selectedItem.id),
                              );
                            } else {
                              task.remove = (task.remove as Klydo[]).filter(
                                (k) => !(k.id === selectedItem.id),
                              );
                            }
                          })
                          .catch(() => {
                            setAlert(`failed to remove klydo'}`);
                            setTimeout(() => {
                              setAlert(undefined);
                            }, 4000);
                          })
                          .finally(v);
                      });
                    }}
                  />
                </Col>
              </Row>
              <Alert show={!!alert} variant="danger">
                <h5 style={{ margin: 2 }}>{alert}</h5>
              </Alert>
            </Modal.Body>
          </Modal>
        )}
      </Modal>
    </>
  );
}

export default ScheduledTaskModal;
