import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const SimpleDropdown = (props: {
  title: string;
  list: Array<string>;
  default: string;
  onSelect: (s: string) => void;
}) => {
  const [curr, setCurr] = useState(props.default);

  return (
    <>
      <Form.Group
        as={Row}
        style={{ paddingLeft: 20, paddingTop: 12 }}
        className="justify-content-left"
      >
        <Col sm="5" md="4">
          <Form.Label as="h5" style={{ paddingTop: 12 }}>
            <b>{props.title}</b>
          </Form.Label>
        </Col>
        <Col xs="6">
          <Form.Select
            value={curr}
            onChange={(e) => {
              props.onSelect(e.target.value);
              setCurr(e.target.value);
            }}
          >
            {props.list.map((s) => (
              <option key={s} value={s}>
                {s.toLowerCase()}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
    </>
  );
};

export default SimpleDropdown;
