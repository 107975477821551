import { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
const SimpleBar = (props: {
  title: string;
  onChange: (e: any) => void;
  max: number;
  default: number;
}) => {
  const [vol, setVol] = useState(props.default);
  return (
    <>
      <Form.Group as={Row} className="justify-content-left">
        <Col xs="6" sm="5" md="4">
          <Form.Label as="h5" style={{ paddingLeft: 20, paddingTop: 12 }}>
            <b>{props.title}</b>
          </Form.Label>
        </Col>
        <Col xs="6">
          <RangeSlider
            variant="info"
            min={0}
            max={props.max}
            tooltip="auto"
            value={vol}
            onChange={(e) => {
              setVol(parseInt(e.target.value));
              props.onChange(e);
            }}
          />
        </Col>
      </Form.Group>
    </>
  );
};

export default SimpleBar;
