import { useEffect, useState } from "react";

const Clock = (props: any): JSX.Element => {
  useEffect(() => {
    const interval = setInterval(
      () =>
        setTime(
          new Date().toLocaleString("en-US", {
            timeZone: props.tz,
            hour12: false,
          }),
        ),
      1000,
    );
    return () => clearInterval(interval);
  }, []);
  const [time, setTime] = useState(
    new Date().toLocaleString("en-US", { timeZone: props.tz, hour12: false }),
  );
  return <p style={{ padding: "10px 15px", margin: 0 }}>LA time: {time}.</p>;
};
export default Clock;
