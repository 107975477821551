import React from "react";
import { Modal } from "react-bootstrap";
import firebaseService from "../firebase_service/firebaseService";

export type KlydoFramesProps = {
  onHide: () => void;
  klydoLoopUrl: string;
};
const KlydoFrames = ({ onHide, klydoLoopUrl }: KlydoFramesProps) => {
  const [frames, setFrames] = React.useState<{ url: string; name: string }[]>(
    [],
  );
  React.useEffect(() => {
    firebaseService.getKlydoFrames(
      klydoLoopUrl.split("/").pop()?.split(".")[0] as string,
      (frames) => setFrames(frames),
    );
  }, [klydoLoopUrl]);
  return (
    <Modal show onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Klydo Frames</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {frames?.length ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {frames.map((image, index) => (
              <a
                key={index}
                href={image.url}
                download={image.name}
                style={{ margin: "10px" }}
              >
                <img
                  src={image.url}
                  alt={`img-${index}`}
                  style={{ width: "200px" }}
                />
              </a>
            ))}
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default KlydoFrames;
