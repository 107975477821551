const firebaseConfig =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? {
        apiKey: "AIzaSyARKs0hpikW3U23NEpU9FMVHYmOddPDInM",
        authDomain: "klydo-development.firebaseapp.com",
        projectId: "klydo-development",
        storageBucket: "klydo-development.appspot.com",
        messagingSenderId: "238468556119",
        appId: "1:238468556119:web:031351b8748228ec04ca12",
        measurementId: "G-D5LJFK9R7G",
      }
    : {
        apiKey: "AIzaSyC5XG3LtScVeWzLYbiYE-JXY4GLFRnGduQ",
        authDomain: "dazzling-mantra-347515.firebaseapp.com",
        projectId: "dazzling-mantra-347515",
        storageBucket: "dazzling-mantra-347515.appspot.com",
        messagingSenderId: "917257785826",
        appId: "1:917257785826:web:8388f97934d30af148a9bb",
        measurementId: "G-GXHN7QYBYW",
      };
export default firebaseConfig;
