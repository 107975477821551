import { Pack, colType } from "Types";
import MsTable from "components/Widgets/Table";
import firebaseService from "firebase_service/firebaseService";
import { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { percentToPresent } from "../utils/util";
import { FaPen, FaTrash } from "react-icons/fa";
import ButtonLoader from "components/Widgets/ButtonLoader";
import { calcTimes } from "components/Widgets/TimeCalc";
import packService from "firebase_service/packService";
import PackReviewModal from "./PackReviewModal";

const PacksReviews = () => {
  const [pack, setPack] = useState<Pack>();
  const [data, setData] = useState<Array<Pack>>();

  useEffect(() => {
    const l = (pack: Pack[]) => {
      setData([...pack]);
    };
    firebaseService.listen<Pack>("packsReviews", l);
    return () => firebaseService.removeListener("packsReviews", l);
  }, []);

  const tableColumns: Array<colType> = [
    {
      sort: true,
      size: 200,
      label: "Name",
      field: "name",
    },
    {
      sort: true,
      label: "Description",
      field: "description",
    },
    {
      size: 150,
      sort: false,
      label: "Cover",
      field: "imageUrl",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "Type",
      field: "type",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "Confirm",
      field: "confirm",
    },
  ];
  const tableRows = (r: Pack, key: string) => {
    return {
      name: r.name,
      description: r.description || "No description",
      imageUrl: r.imageUrl ? (
        <img
          style={{ borderRadius: 50 + "%" }}
          loading="lazy"
          height={96}
          width={96}
          alt="profile"
          src={r.imageUrl}
        ></img>
      ) : (
        "No Image"
      ),
      reviewDate: r.review?.date?.toLocaleString("en-GB"),
      times: percentToPresent(calcTimes(r.times ?? [])),
      confirm: (
        <div
          className="fa-solid fa-check"
          title={
            r.review?.type === "delete"
              ? "Delete Klydo"
              : r.zip && r.review?.type === "edit"
                ? "Publish Klydo"
                : "Not ready"
          }
          style={{ padding: 5 }}
          onClick={(e) => {
            e.stopPropagation();
            if (r.zip || r.review?.type === "delete") setPack(r);
          }}
        ></div>
      ),
      type: r.review?.type === "delete" ? <FaTrash /> : <FaPen />,
      zip: (r.zip ?? false) + "",
      key: key,
    };
  };
  return (
    <>
      {data ? (
        <>
          <MsTable
            id="reviews"
            cols={tableColumns}
            modal={PackReviewModal}
            initSort={"reviewDate"}
            rowBuild={tableRows}
            title={"Reviews"}
            data={data!}
            search={(row: Pack, val: string) => {
              return (
                row.name.toLowerCase().includes(val) ||
                row.id.toLowerCase().includes(val) ||
                row.description?.toLowerCase().includes(val) ||
                false
              );
            }}
          />
        </>
      ) : (
        <Spinner></Spinner>
      )}
      <Modal show={pack !== undefined} onHide={() => setPack(undefined)}>
        <Modal.Header style={{ justifyContent: "right" }}>
          <i
            className="nc-icon nc-simple-remove"
            onClick={() => setPack(undefined)}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <h4>
            Are you sure you want to
            {pack?.review?.type === "edit" ? "make it" : "remove it from"}
            public?
          </h4>
          <h5>{pack?.name}</h5>
          <Button
            style={{ width: 180, margin: 10 }}
            onClick={() => setPack(undefined)}
          >
            No
          </Button>
          <ButtonLoader
            style={{ width: 180, margin: 10 }}
            title="Yes"
            onClick={() => {
              return new Promise<void>((v, x) => {
                packService
                  .acceptPackReview(pack!, pack?.review?.type === "delete")
                  .then(() => {
                    setPack(undefined);
                    v();
                  })
                  .catch(x);
              });
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PacksReviews;
