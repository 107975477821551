import { useEffect, useState } from "react";
import firebaseService from "../firebase_service/firebaseService";
import bg from "../assets/img/splash.svg";
// import '../assets/css/splash-screen.css';
import logo from "assets/img/logo-light.png";
import logoDark from "assets/img/logo-dark.png";
import { Button } from "react-bootstrap";

const ALLOWED_ROLES = ["admin", "viewer"];

function SplashScreen() {
  return (
    <div
      id="fuse-splash-screen"
      style={{ background: "#0F0F22", backgroundImage: `url(${bg})` }}
    >
      <div className="center">
        <div className="logo">
          <img width="128" src={logo} alt="..." />
        </div>
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="inner">
              <div className="gap" />
              <div className="left">
                <div className="half-circle" />
              </div>
              <div className="right">
                <div className="half-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ErrorScreen = (err: any) => (
  <div className="login">
    <div className="logo" style={{ marginLeft: 40 }}>
      <img width="128" src={logoDark} alt="..." />
    </div>
    <h5 className="mx-auto mt-3" style={{ width: 208 }}>
      {err ? "Oops... somthing went wrong" : err}
    </h5>
    <Button href="/login" style={{ width: 208, marginLeft: 20 }}>
      Return to Login
    </Button>
  </div>
);

function withSplashScreen(WrappedComponent: any) {
  return () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    useEffect(() => {
      firebaseService.onAuthStateChanged(
        (authUser: firebase.default.User | undefined) => {
          if (!authUser) {
            setUser(false);
            setLoading(false);
            return;
          }
          authUser.getIdTokenResult().then((token) => {
            if (ALLOWED_ROLES.includes(token.claims.role)) {
              setUser(true);
              setLoading(false);
            } else {
              firebaseService.logOut();
              setError({ error: "User doesn't have permision" });
              setLoading(false);
            }
            setLoading(false);
          });
        },
      );
    }, []);
    // while checking user session, show "loading" message
    if (loading) return SplashScreen();

    if (error) return ErrorScreen(typeof error == "string" ? error : "");

    // otherwise, show the desired route
    return <WrappedComponent isLoggedIn={user} />;
  };
}

export default withSplashScreen;
