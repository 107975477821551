import { useEffect, useRef } from "react";
import { useOnScreen } from "../hooks";
import clockImage from "../../assets/img/klydo_2_medium_clean.png";
import { isVideo } from "../../utils/util";

type ClockViewProps = {
  clockBodyTopOffset: number;
  clockBodyUrl: string;
  clockSize: number;
  colorScheme: string;
  loopUrl: string;
  pendulumLoopUrl: string;
  gifSize: number;
  themeName: string;
  themeID: string;
  handsColor: string;
  pendulumColor: string;
  backgroundColor: string;
  pendulumRodColor: string;
  dialsColor: string;
  hourHandWidth: number;
  isClockInDisplayMode: boolean;
  isPrototypeDisplay: boolean;
  isSecondHandVisible: boolean;
  minuteHandWidth: number;
  showBody: boolean;
  threadLength: number;
  threadWidth: number;
  timeShift: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  scale: number;
  crop?: { left: number; top: number; zoom: number };
  showDials?: boolean;
} & typeof defaultClockViewProps;

const defaultClockViewProps = {
  clockBodyTopOffset: 30,
  clockBodyUrl: "",
  clockSize: 220,
  colorScheme: "light",
  loopUrl: "",
  pendulumLoopUrl: "",
  gifSize: 230,
  themeName: "fox",
  themeID: "80328038108203280",
  handsColor: "#aeaeae",
  hourHandWidth: 14,
  isClockInDisplayMode: false,
  isPrototypeDisplay: false,
  isSecondHandVisible: false,
  minuteHandWidth: 10,
  showBody: true,
  threadLength: 2,
  threadWidth: 2,
  timeShift: 0,
  scale: 1,
};
const secondsForMin = 3600;
const secondsForHour = 43200;
const ClockView = (props: ClockViewProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const handMin = useRef<HTMLDivElement>(null);
  const handHour = useRef<HTMLDivElement>(null);
  const handHourS = useRef<HTMLDivElement>(null);
  const handMinS = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const ii = useRef<any>();

  useEffect(() => {
    const updHands = () => {
      const dt = new Date();
      dt.setTime(props.timeShift + dt.getTime());
      const seconds = dt.getMinutes() * 60 + dt.getSeconds();
      const dgmin = (seconds / secondsForMin) * 360;
      if (handMin.current != null)
        handMin.current.style.transform = "rotate(" + dgmin + "deg)";
      if (handMinS.current != null)
        handMinS.current.style.transform = "rotate(" + dgmin + "deg)";
      const hours = (dt.getHours() % 12) * 3600 + seconds;
      const dgh = (hours / secondsForHour) * 360;
      if (handHour.current != null)
        handHour.current.style.transform = "rotate(" + dgh + "deg)";
      if (handHourS.current != null)
        handHourS.current.style.transform = "rotate(" + dgh + "deg)";
    };
    if (!isVisible) {
      clearInterval(ii.current);
    } else {
      ii.current = setInterval(() => {
        updHands();
      }, 2000);
      updHands();
    }
    return () => {
      clearInterval(ii.current);
    };
  }, [isVisible, props.timeShift]);
  return (
    <div
      ref={ref}
      style={{ position: "absolute", width: "100%", height: "100%" }}
    >
      <div //background
        style={{
          position: "absolute",
          top: "5%",
          left: "5%",
          width: "90%",
          height: "90%",
          backgroundColor: props.backgroundColor,
        }}
      />

      <div //pendulum element
        style={{
          left: "35%",
          top: "29%",
          width: "30%",
          height: "60%",
          position: "absolute",
          transformOrigin: "50% 0px",
          animation: "pendulum-swing 2s ease-in-out infinite alternate",
        }}
      >
        <div //rod
          style={{
            position: "absolute",
            left: "48.5%",
            transformOrigin: "50% 0px",
            backgroundColor: props.pendulumRodColor,
            width: "4%",
            height: "71%",
          }}
        ></div>
        <div //disc
          style={{
            position: "absolute",
            borderRadius: "50%",
            padding: "50%",
            width: "100%",
            bottom: 0,
            backgroundColor: props.pendulumColor,
            boxShadow: "rgba(0, 0, 0, 0.55) 0px 2px 3.2px 0px",
          }}
        ></div>
      </div>
      <div
        style={{
          width: "77%",
          aspectRatio: 1,
          top: "6.95%",
          left: "11.6%",
          position: "absolute",
        }}
      >
        {props.showDials &&
          [
            {
              x: 72.3334,
              y: 8.130206022131384,
            },
            {
              x: 89.53659397786862,
              y: 25.333400000000005,
            },
            {
              x: 95.8334,
              y: 48.8334,
            },
            {
              x: 89.53659397786862,
              y: 72.3334,
            },
            {
              x: 72.3334,
              y: 89.5365939778686,
            },
            {
              x: 48.8334,
              y: 95.8334,
            },
            {
              x: 25.33340000000001,
              y: 89.53659397786862,
            },
            {
              x: 8.130206022131377,
              y: 72.3334,
            },
            {
              x: 1.8334,
              y: 48.833400000000005,
            },
            {
              x: 8.130206022131384,
              y: 25.333399999999997,
            },
            {
              x: 25.33339999999998,
              y: 8.130206022131398,
            },
            {
              x: 48.83339999999999,
              y: 1.8334,
            },
          ].map(({ x, y }, index) => {
            return (
              <div
                key={index}
                style={{
                  top: `${y}%`,
                  left: `${x}%`,
                  position: "absolute",
                  fontSize: "1.2em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                  color: props.dialsColor,
                  borderRadius: "50%",
                  width: "2.333%",
                  height: "2.333%",
                  backgroundColor: props.dialsColor,
                }}
              ></div>
            );
          })}
        {isVideo(props.loopUrl) === "video" ? ( //gif is video
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: `${props.crop?.top || 0}%`,
              left: `${props.crop?.left || 0}%`,
              width: `${props.crop?.zoom || 100}%`,
              aspectRatio: "1/1",
            }}
          >
            <video
              muted={true}
              src={props.loopUrl}
              loop
              autoPlay
              style={{ width: "auto", height: "100%", objectFit: "cover" }}
            />
          </div>
        ) : isVideo(props.loopUrl) === "image" ? ( //gif is img
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: `${props.crop?.top || 0}%`,
              left: `${props.crop?.left || 0}%`,
              width: `${props.crop?.zoom || 100}%`,
              aspectRatio: "1/1",
            }}
          >
            <img
              loading="lazy"
              src={props.loopUrl}
              alt=""
              style={{ width: "auto", height: "100%", objectFit: "cover" }}
            />
          </div> //gif is none
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30%",
            }}
          >
            <p style={{ zIndex: 20 }}>File type not supported</p>
          </div>
        )}
      </div>
      <time
        dateTime="2023-06-30T04:48:07.668Z"
        style={{
          padding: "38.5%",
          top: "6.95%",
          position: "absolute",
          left: "11.6%",
        }}
      >
        <div //hour shadow
          ref={handHourS}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "0.4%",
            top: "1.2%",
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: "4px",
              width: "3%",
              height: "25%",
              bottom: "46%",
              left: "48%",
              background:
                "radial-gradient(ellipse at center, #000000 0%, #00000000 100%)",
            }}
          ></div>
        </div>
        <div //hour
          ref={handHour}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: "4px",
              width: "3%",
              height: "25%",
              bottom: "46%",
              left: "48%",
              backgroundColor: props.handsColor,
            }}
          ></div>
        </div>
        <div //minute shadow
          ref={handMinS}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "1.2%",
            top: "1.4%",
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: "4px",
              width: "2.5%",
              height: "40%",
              bottom: "45%",
              left: "48.75%",
              background:
                "radial-gradient(ellipse at center, #000000 0%, #00000000 100%)",
            }}
          ></div>
        </div>
        <div //minute
          ref={handMin}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: "4px",
              width: "2.5%",
              height: "40%",
              bottom: "45%",
              left: "48.75%",
              backgroundColor: props.handsColor,
            }}
          ></div>
        </div>
      </time>
      <img
        loading="lazy"
        src={clockImage}
        alt="Klydo Clock"
        style={{ top: 0, width: "100%", height: "auto", position: "absolute" }}
      />
    </div>
  );
};

ClockView.defaultProps = defaultClockViewProps;
export default ClockView;
