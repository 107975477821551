export const MyImdb = () => (
  <svg
    enable-background="new 0 0 100 100"
    height="100px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 100 100"
    width="100px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle cx="50" cy="50" fill="#E0AB00" r="45" />
      <g>
        <path
          clip-rule="evenodd"
          d="M43.848,59.641c0-4.309,0-8.607,0-12.906    c-0.014,0-0.028,0-0.043,0c-0.577,4.297-1.155,8.596-1.734,12.902c-1.061,0-2.11,0-3.177,0c-0.61-4.234-1.219-8.465-1.829-12.697    c-0.012,0-0.021,0-0.033,0.002c0,4.229,0,8.455,0,12.695c-1.488,0-2.956,0-4.423,0c-0.037-0.131-0.054-19.213-0.018-19.543    c0.139-0.039,6.106-0.059,6.577-0.018c0.03,0.174,0.066,0.355,0.093,0.537c0.167,1.178,0.33,2.357,0.498,3.535    c0.128,0.893,0.259,1.787,0.388,2.68c0.109,0.752,0.217,1.504,0.358,2.258c0.384-3,0.768-6.004,1.153-9.012    c2.214,0,4.412,0,6.609,0c0.042,0.135,0.051,19.371,0.007,19.566C46.813,59.641,45.352,59.641,43.848,59.641z"
          fill="#000000"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M49.737,59.648c0-6.535,0-13.045,0-19.57    c0.051-0.004,0.098-0.014,0.145-0.014c1.914,0.01,3.827,0.01,5.74,0.037c0.902,0.012,1.807,0.07,2.694,0.254    c0.806,0.168,1.551,0.471,2.132,1.082c0.535,0.564,0.862,1.23,0.957,2.006c0.114,0.922,0.13,1.852,0.133,2.779    c0.009,2.711,0.011,5.422-0.001,8.135c-0.002,0.723-0.052,1.443-0.099,2.166c-0.03,0.453-0.128,0.898-0.318,1.318    c-0.312,0.686-0.854,1.117-1.536,1.398c-0.489,0.199-1.005,0.297-1.527,0.33c-0.678,0.043-1.357,0.072-2.035,0.076    c-2.014,0.01-4.026,0.002-6.04,0.002C49.908,59.648,49.834,59.648,49.737,59.648z M54.838,43.426    c-0.038,0.271-0.025,12.717,0.011,12.861c0.316,0.004,0.631-0.018,0.934-0.127c0.256-0.092,0.439-0.258,0.5-0.533    c0.027-0.127,0.057-0.256,0.076-0.383c0.085-0.551,0.094-1.107,0.094-1.66c0.004-2.666,0.004-5.334-0.002-8    c0-0.422-0.025-0.846-0.059-1.268c-0.032-0.395-0.218-0.688-0.62-0.799C55.47,43.438,55.16,43.42,54.838,43.426z"
          fill="#000000"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M67.566,58.449c-0.112,0.42-0.216,0.807-0.317,1.189    c-1.531,0-3.043,0-4.565,0c0-6.525,0-13.039,0-19.564c1.622,0,3.234,0,4.873,0c0.003,0.07,0.009,0.141,0.009,0.211    c0.001,1.385,0,2.768,0,4.15c0,0.066,0,0.133,0,0.199c0.013,0.004,0.024,0.008,0.037,0.012c0.063-0.064,0.128-0.129,0.191-0.193    c0.733-0.748,1.624-1.133,2.673-1.146c0.642-0.008,1.267,0.078,1.859,0.344c0.838,0.373,1.34,1.029,1.507,1.916    c0.083,0.438,0.084,0.893,0.086,1.34c0.011,1.701,0.007,3.402,0.007,5.104c0.001,1.146,0.002,2.293,0,3.439    c-0.001,0.645-0.045,1.281-0.136,1.92c-0.125,0.873-0.579,1.521-1.297,2.006c-0.702,0.475-1.484,0.629-2.315,0.555    c-0.952-0.086-1.726-0.529-2.371-1.219C67.731,58.631,67.658,58.549,67.566,58.449z M69.031,51.654L69.031,51.654    c0.001-1.271,0.004-2.543-0.001-3.814c-0.002-0.383-0.011-0.773-0.103-1.15c-0.107-0.441-0.461-0.641-0.896-0.508    c-0.174,0.051-0.296,0.162-0.344,0.336c-0.035,0.125-0.065,0.256-0.073,0.383c-0.022,0.418-0.046,0.834-0.047,1.252    c-0.004,2.307-0.002,4.611-0.001,6.916c0,0.256-0.004,0.512,0.016,0.766c0.021,0.273,0.058,0.547,0.107,0.814    c0.035,0.186,0.146,0.328,0.328,0.404c0.425,0.176,0.815-0.021,0.919-0.469c0.011-0.047,0.023-0.098,0.026-0.146    c0.023-0.367,0.063-0.732,0.065-1.1C69.037,54.109,69.031,52.883,69.031,51.654z"
          fill="#000000"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M31.145,40.076c0,6.529,0,13.037,0,19.561    c-1.691,0-3.376,0-5.071,0c0-6.52,0-13.033,0-19.561C27.761,40.076,29.445,40.076,31.145,40.076z"
          fill="#000000"
          fill-rule="evenodd"
        />
      </g>
    </g>
  </svg>
);
export const MyNitfy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 228 233"
    fill="none"
    data-testid="navbar-logo-dark"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M139.265 13.7886H88.2471C67.0573 13.7886 52.552 13.8179 41.6689 15.2811C31.1824 16.691 26.1463 19.2007 22.6735 22.6735C19.2007 26.1463 16.691 31.1824 15.2811 41.6689C13.8179 52.552 13.7886 67.0573 13.7886 88.2471V144.106C13.7886 165.296 13.8179 179.801 15.2811 190.684C16.691 201.17 19.2007 206.207 22.6735 209.679C26.1463 213.152 31.1824 215.662 41.6689 217.072C52.552 218.535 67.0573 218.564 88.2471 218.564H139.265C160.455 218.564 174.96 218.535 185.843 217.072C196.33 215.662 201.366 213.152 204.839 209.679C208.311 206.207 210.821 201.17 212.231 190.684C213.694 179.801 213.724 165.296 213.724 144.106V88.2472C213.724 67.0573 213.694 52.552 212.231 41.6689C210.821 31.1824 208.311 26.1463 204.839 22.6735C201.366 19.2007 196.33 16.691 185.843 15.2811C174.96 13.8179 160.455 13.7886 139.265 13.7886ZM12.9235 12.9235C0 25.847 0 46.647 0 88.2471V144.106C0 185.706 0 206.506 12.9235 219.429C25.847 232.353 46.647 232.353 88.2471 232.353H139.265C180.865 232.353 201.665 232.353 214.589 219.429C227.512 206.506 227.512 185.706 227.512 144.106V88.2472C227.512 46.647 227.512 25.847 214.589 12.9235C201.665 0 180.865 0 139.265 0H88.2471C46.647 0 25.847 0 12.9235 12.9235Z"
      fill="#000000"
    ></path>
    <path
      d="M140.053 60.876V142.789L87.3825 60.876H64.1211V177.183H85.2225V94.2727L138.89 177.183H161.154V60.876H140.053Z"
      fill="#000000"
    ></path>
  </svg>
);
