import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "./layouts/Admin";
import Login from "./layouts/Login";
import withSplashScreen from "./layouts/SplashScreen";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();
function App(props: { isLoggedIn: boolean }) {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {props.isLoggedIn ? (
            <Route path="admin/*" element={<AdminLayout />} />
          ) : (
            <Route path="login" element={<Login />} />
          )}
          <Route
            path="*"
            element={
              <Navigate to={props.isLoggedIn ? "admin/dashboard" : "login"} />
            }
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default withSplashScreen(App);
