import axios from "axios";
import { baseUrl, getToken } from "./baseRequest";

export type KlydoApiClient = {
  search: (
    query: string,
    filters?: {
      excludeIds?: string[];
    },
  ) => Promise<{ ids: string[] }>;
};

export type KlydoSearchFilter = { excludeIds: string[] };

const klydoApiClient: KlydoApiClient = {
  search: async (query, { excludeIds } = {}) => {
    const token = await getToken();
    if (!token) throw new Error("No token found");

    return axios
      .post(
        `${baseUrl}/klydos/.search?query=${query}`,
        {
          filters: { excludeIds },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => res.data);
  },
};
export default klydoApiClient;
