import { useEffect, useState } from "react";
import firebaseService from "firebase_service/firebaseService";
import { User } from "Types";

const useUsers = () => {
  const [users, setUsers] = useState<Array<User>>([]);
  useEffect(() => {
    const l = (users: Array<User>) => {
      setUsers([...users]);
    };
    firebaseService.listen<User>("users", l);
    return () => firebaseService.removeListener("users", l);
  }, []);

  return users;
};

export default useUsers;
