import { useState } from "react";
import { Form } from "react-bootstrap";

const SimpleSwitch = (props: {
  title: string;
  default: boolean;
  onClick: (on: boolean) => void;
  on: string[] | undefined;
}) => {
  const [on, setOn] = useState(props.default);
  const s = props.on ? props.on : [" on", " off"];
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Form.Check
        style={{ lineHeight: 1.5 }}
        type="switch"
        checked={on}
        onChange={() => {
          props.onClick(!on);
          setOn(!on);
        }}
      ></Form.Check>
      <span
        onClick={() => {
          props.onClick(!on);
          setOn(!on);
        }}
      >
        <b>{String(props.title + (on ? s[0] : s[1]))}</b>
      </span>
    </div>
  );
};

export default SimpleSwitch;
