import { Tag, colType } from "Types";
import MsTable from "components/Widgets/Table";
import firebaseService from "firebase_service/firebaseService";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

const Tags = () => {
  const [data, setData] = useState<Array<Tag>>();
  const [deletingTag, setDeletingTag] = useState<Tag>();
  useEffect(() => {
    const l = (tags: Array<Tag>) => {
      setData(tags.filter((tag) => !(tag.name.substring(0, 2) === "$p")));
    };
    firebaseService.listen<Tag>("tags2", l);
    return () => firebaseService.removeListener("tags2", l);
  }, []);

  const tableColumns: Array<colType> = [
    {
      size: 100,
      sort: true,
      label: "Hashtag",
      field: "name",
    },
    {
      sort: true,
      label: "Total klydos use",
      field: "total",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];
  const tableRows = (r: Tag, key: string) => {
    return {
      name: (
        <p style={{ margin: 0, padding: 0, textAlign: "left" }}>{r.name}</p>
      ),
      total: r.total,
      delete:
        deletingTag?.name === r.name ? (
          <Spinner></Spinner>
        ) : (
          <i
            style={{ margin: 8 + "px" }}
            className="nc-icon nc-simple-remove"
            onClick={(e) => {
              setDeletingTag(r);
              e.stopPropagation();
              firebaseService
                .deleteTag(r)
                .catch((x) => {
                  console.log(x);
                })
                .finally(() => setDeletingTag(undefined));
            }}
          />
        ),
      key: key,
    };
  };
  return (
    <>
      {data ? (
        <MsTable
          id="tags"
          cols={tableColumns}
          initSort="name"
          rowBuild={tableRows}
          title={"tags"}
          data={data!}
          search={(row: Tag, val: string) => {
            return row.name.toLowerCase().includes(val);
          }}
        />
      ) : (
        <Spinner></Spinner>
      )}
    </>
  );
};

export default Tags;
