import React from "react";
import "./Packview.css"; // Import the CSS file for styling
import pack_medium from "../../assets/img/pack_medium.png"; // Import the image file

export type PackviewProps = {
  packName?: string;
  clockBackground?: string;
  pendulumBackground?: string;
};

const Packview = ({
  packName,
  clockBackground,
  pendulumBackground,
}: PackviewProps) => {
  return (
    <div className="clock-container">
      <div className="clock">
        {clockBackground && (
          <img
            src={clockBackground}
            alt="Clock Background"
            className="clock-background"
          />
        )}
        <div className="pack-title">{packName}</div>
        <img src={pack_medium} alt="Wall Clock" className="wall-clock" />
        {pendulumBackground && (
          <div
            className="pendulum"
            style={{ backgroundImage: `url(${pendulumBackground})` }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Packview;
