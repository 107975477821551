import { useState } from "react";
import firebaseService from "firebase_service/firebaseService";
import { Form, Button, Row, Col } from "react-bootstrap";

function Login() {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ email: "", password: "" });

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    if (form.checkValidity()) {
      firebaseService
        .loginWithEmail({ email: email, password: password })
        .catch((e) => setError(e));

      setValidated(true);
    }
  };

  return (
    <div className="login">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="logo">
          <img width="300vh" src="logos/logo-light.png" alt="..." />
        </div>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            isInvalid={error.email !== ""}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter email"
            required
          />
          <Form.Control.Feedback type="invalid">
            {error.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            isInvalid={error.password !== ""}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            required
          />
          <Form.Control.Feedback type="invalid">
            {error.password}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Button
            variant="primary"
            type="submit"
            style={{ borderRadius: "25rem", width: "100%", margin: 10 }}
          >
            Login
          </Button>
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Col>
            <Row className="justify-content-md-center">
              <h6>Or</h6>
            </Row>
            <Row>
              <Button
                style={{ margin: 10 }}
                onClick={() => {
                  firebaseService
                    .loginSocial("google")
                    .catch((e) => setError(e));
                }}
              >
                Continue with Google
              </Button>
              <Button
                style={{ margin: 10 }}
                onClick={() => {
                  firebaseService
                    .loginSocial("facebook")
                    .catch((e) => setError(e));
                }}
              >
                Continue with Facebook
              </Button>
            </Row>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Login;
