import { useEffect, useState } from "react";
const calcTime = (props: {
  offset: number;
  time: { local: number; server: Date };
}) => {
  const t = props.time;
  const offset = props.offset || 0;
  let am = true;
  if (!t) return "-no info-";
  const n = t.server;
  const dif =
    ((t.local + offset) % 1440) - (n.getUTCHours() * 60 + n.getUTCMinutes());
  const now = new Date();
  now.setMinutes(now.getMinutes() + dif);
  const timeString = now.toISOString().split("T")[1];
  const split: Array<any> = timeString.split(":");
  split[0] = parseInt(split[0]);
  am = split[0] < 12;
  if (dif < 0) am = !am;
  split[0] %= 12;
  if (split[0] === 0) split[0] = 12;
  return ` ${split[0]}:${split[1]} ${am ? "AM" : "PM"}`;
};
const CurrTime = (props: any) => {
  const [time, setTime] = useState(calcTime(props));
  useEffect(() => {
    const intervalID = setInterval(() => setTime(calcTime(props)), 1_000);
    return () => clearInterval(intervalID);
  });
  return <p style={{ margin: "auto" }}>{time}</p>;
};
export const calcClockTime = calcTime;
export default CurrTime;
