import { useEffect, useMemo, useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";

const Editable = (props: {
  title: string;
  default: string;
  onSet: (value: string) => Promise<any>;
  editIcon?: any;
  viewAs?: any;
}) => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const val = useRef(props.default);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {active ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Control
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              val.current = e.target.value;
            }}
            defaultValue={props.default}
          />
        </div>
      ) : props.viewAs ? (
        props.viewAs
      ) : (
        <p>{val.current}</p>
      )}
      {error && <p style={{ fontSize: 12, color: "red" }}>{error}</p>}
      {active ? (
        <span
          onClick={(e) => {
            e.stopPropagation();
            setError("");
            setActive(false);
            setLoading(true);
            props
              .onSet(val.current)
              .catch((e) => {
                setError(e.toLocaleString());
                console.log(e);
              })
              .finally(() => setLoading(false));
          }}
          className="nc-icon nc-check-2"
        ></span>
      ) : loading ? (
        <Spinner animation="border" size="sm" />
      ) : props.editIcon ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setActive(true);
          }}
        >
          {props.editIcon}
        </div>
      ) : (
        <span
          onClick={(e) => {
            e.stopPropagation();
            setActive(true);
          }}
          className="nc-icon nc-tag-content"
        ></span>
      )}
    </div>
  );
};

export default Editable;
