import { Device, Pack } from "Types";
import MsTable from "components/Widgets/Table";
import firebaseService from "firebase_service/firebaseService";
import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import FloatAddButton from "components/Widgets/FloatAddButton";
import PackModal from "./PackModal";
import ButtonLoader from "components/Widgets/ButtonLoader";
import packService from "firebase_service/packService";
import PackPoolSwitch from "./PackPoolSwitch";

const Packs = () => {
  const [packs, setPacks] = useState<Pack[]>();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [deletePack, setDeletePack] = useState<Pack>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>();
  const [premDevices, setPremDevices] = useState<Device[]>();
  useEffect(() => {
    const lPacks = (packs: Pack[]) => {
      setPacks([...packs]);
    };
    firebaseService.listen<Pack>("packs", lPacks);

    return () => {
      firebaseService.removeListener("packs", lPacks);
    };
  }, []);

  const handlePackUpdate = (pack: Pack) => {
    if (pack) {
      setPacks((packs ?? []).map((p) => (p.id === pack?.id ? pack : p)));
    }
  };

  const DeleteModal = () => {
    const [deleteMsg, setDeleteMsg] = useState("");
    const [warning, setWarning] = useState("");
    return (
      <Modal
        onHide={() => {
          setShowDeleteModal(false);
          setDeletePack(undefined);
        }}
        className="modal-medium"
        show={true}
      >
        <Modal.Body>
          <Row style={{ margin: 25, marginBottom: 0 }}>
            <h4 style={{ textAlign: "center" }}>
              You are about to delete pack {deletePack!.name}
            </h4>
          </Row>
          {/* <Row style={{ marginBottom: "20px" }}>
            {!premDevices ? (
              <Spinner />
            ) : premDevices.length > 0 ? (
              <div>
                <h4 style={{ textAlign: "center", margin: 0 }}>
                  Devices on pack: ({premDevices.length})
                </h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    maxHeight: "140px",
                    overflowY: "auto",
                  }}
                >
                  {premDevices.map((dev) => (
                    <p style={{ margin: 0 }}>- {dev.idf}</p>
                  ))}
                </div>
              </div>
            ) : (
              <h4 style={{ textAlign: "center" }}>No devices on Pack</h4>
            )}
          </Row> */}
          <Row className="h-100">
            <Form.Control
              as="textarea"
              rows={4}
              value={deleteMsg}
              style={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => setDeleteMsg(e.target.value)}
            ></Form.Control>
          </Row>
          <Row className="justify-content-between">
            <Col sm="5">
              <Button
                className="w-100 h-100"
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeletePack(undefined);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col sm="5">
              <ButtonLoader
                variant="outline-danger"
                className="w-100 h-100"
                title="Delete"
                onClick={async () => {
                  if (!deleteMsg) {
                    setWarning("Must enter a message to delete");
                    return;
                  }
                  if (!deleteMsg) {
                    const user = await firebaseService.findItem<Pack>(
                      "draftPacks",
                      "id",
                      deletePack!.id,
                    );
                    if (user?.lastRefreshTime) {
                      setWarning("Must enter a message to delete");
                      return;
                    }
                  }
                  setWarning("");
                  if (deletePack) {
                    try {
                      await packService.acceptPackReview(
                        deletePack!,
                        true,
                        deleteMsg,
                      );
                    } catch (e: any) {
                      setWarning(e.message || "an error occured");
                    }
                  }
                  setShowDeleteModal(false);
                  await firebaseService.deletePack(deletePack!).then(() => {
                    setDeletePack(undefined);
                  });
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };
  const tableColumns: Array<{
    sort: boolean;
    size?: number;
    label: string;
    field: string;
    noHeadline?: boolean;
  }> = [
    {
      sort: true,
      size: 200,
      label: "Name",
      field: "name",
    },
    {
      sort: true,
      label: "Description",
      field: "description",
    },
    {
      size: 112,
      sort: false,
      label: "Cover",
      field: "imageUrl",
    },
    {
      size: 128,
      sort: false,
      label: "Pool",
      field: "pool",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];
  const tableRows = (r: Pack, key: string) => {
    return {
      name: r.name,
      description: r.description || "No description",
      imageUrl: r.imageUrl ? (
        <img
          style={{ borderRadius: 50 + "%" }}
          loading="lazy"
          height={90}
          width={90}
          alt="profile"
          src={r.imageUrl}
        ></img>
      ) : (
        "No Image"
      ),
      pool: <PackPoolSwitch pack={r} handlePackUpdate={handlePackUpdate} />,
      delete:
        deletePack && deletePack.id === r.id && !showDeleteModal ? (
          <Spinner />
        ) : (
          <i
            style={{ margin: 8 + "px" }}
            className="nc-icon nc-simple-remove"
            onClick={(e) => {
              e.stopPropagation();
              setDeletePack(r);
              setShowDeleteModal(true);
            }}
          />
        ),
      key: key,
    };
  };
  return (
    <>
      {packs ? (
        <>
          <MsTable
            id="packs"
            cols={tableColumns}
            modal={PackModal}
            initSort="name"
            rowBuild={tableRows}
            title={"Packs"}
            data={packs}
            handleChangedPack={handlePackUpdate}
            search={(row: Pack, val: string) => {
              return (
                row.name.toLowerCase().includes(val) ||
                row.id.toLowerCase().includes(val) ||
                row.description?.toLowerCase().includes(val) ||
                false
              );
            }}
          />
          <FloatAddButton onClick={() => setShowAdd(true)} />
        </>
      ) : (
        <Spinner></Spinner>
      )}
      {showAdd && (
        <Modal show={true} size="xl" onHide={() => setShowAdd(false)}>
          <PackModal
            setShowModal={() => setShowAdd(false)}
            data={packs!}
          ></PackModal>
        </Modal>
      )}

      {showDeleteModal && <DeleteModal />}
    </>
  );
};

export default Packs;
