import { useState, useEffect, useRef } from "react";

interface UseWebSocketProps {
  uri: string;
  devId: string;
}

export const useWebSocket = ({ uri, devId }: UseWebSocketProps) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const logElement = useRef<HTMLDivElement>(null);
  const keepAliveInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.close();
      }
      if (keepAliveInterval.current) {
        clearInterval(keepAliveInterval.current);
      }
    };
  }, [socket]);

  const start = () => {
    if (!uri || !devId) return;

    setLoadingMessage("Waiting for server...");

    const ws = new WebSocket(uri);
    setSocket(ws);

    ws.onopen = () => {
      setLoadingMessage(null);
      setIsConnected(true);
      ws.send(JSON.stringify({ admin: devId }));
    };

    ws.onmessage = (msg) => {
      const data = msg.data;
      if (data === "ready") {
        setLoadingMessage(null);
        setIsReady(true);
        keepAliveInterval.current = setInterval(() => {
          ws.send("keep-alive");
        }, 60000);
      } else if (data === "finish") {
        ws.close();
      } else {
        if (logElement.current) {
          logElement.current.innerText += `${data}\n`;
          logElement.current.scrollTop = logElement.current.scrollHeight;
        }
      }
    };

    ws.onclose = () => {
      setIsConnected(false);
      setSocket(null);
      if (keepAliveInterval.current) {
        clearInterval(keepAliveInterval.current);
        keepAliveInterval.current = null;
      }
    };
  };

  const stop = () => {
    if (socket) {
      socket.send("finish");
      socket.close();
    }
    setSocket(null);
    setIsConnected(false);
    setIsReady(false);

    if (keepAliveInterval.current) {
      clearInterval(keepAliveInterval.current);
      keepAliveInterval.current = null;
    }
  };

  const clearLog = () => {
    if (logElement.current) {
      logElement.current.innerText = "";
    }
  };

  const sendCommand = (command: string) => {
    if (isConnected && socket && isReady) {
      socket.send(command);
    }
  };

  return {
    start,
    stop,
    sendCommand,
    isConnected,
    isReady,
    loadingMessage,
    logElement,
    clearLog,
  };
};
