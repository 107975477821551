import { myColor } from "../../utils/util";

const FloatAddButton = (props: { onClick: () => void }) => (
  <div
    style={{
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      background: myColor,
      justifyContent: "center",
      height: "48px",
      width: "48px",
      bottom: "40px",
      right: "40px",
      borderRadius: "50%",
    }}
    onClick={() => props.onClick()}
  >
    <div
      className="icon-big text-center"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: 48,
        width: 48,
      }}
    >
      <i
        className="nc-icon nc-simple-add"
        style={{
          fontSize: "1.8em",
          fontWeight: "bold",
          color: "white",
        }}
      ></i>
    </div>
  </div>
);

export default FloatAddButton;
