import Select from "react-select";

export type MultiSelectProps = {
  options: Array<{ label: string; value: string }>;
  onChange: (newVal: string[]) => void;
  value: string[];
  label: string;
};

const MultiSelect = (props: MultiSelectProps) => {
  return (
    <Select
      isMulti
      options={props.options}
      value={props.options.filter((o) => props.value.includes(o.value))}
      onChange={(e) => {
        e && props.onChange(e.map((o) => o.value));
      }}
    />
  );
};

export default MultiSelect;
