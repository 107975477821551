import { useState } from "react";
import bb from "../assets/img/Box_all.gif";

// react-bootstrap components
import {
  Button,
  Spinner,
  Container,
  Row,
  Col,
  Card,
  Modal,
  Form,
  InputGroup,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import firebaseService from "../firebase_service/firebaseService";
import ButtonLoader from "../components/Widgets/ButtonLoader";
import SimpleSwitch from "components/Widgets/SimpleSwitch";
import SimpleBar from "components/Widgets/SimpleBar";
import SimpleDropdown from "components/Widgets/SimpleDropdown";
import {
  isVideo,
  myColor,
  resizeCloudinary,
  secondsToTime,
} from "../utils/util";
import SimpleTimePicker from "components/Widgets/SimpleTimePicker";
import { Device, Klydo, colType } from "Types";
import { FaPowerOff, FaTrash } from "react-icons/fa";
import Editable from "components/Widgets/Editable";
import MsTable from "components/Widgets/Table";
import { Link, NavLink } from "react-router-dom";
import PackList from "components/Widgets/PackList";

const NightMode = ["AUTO", "SCHEDULE", "OFF"];
const Show = ["FIXED", "DAY", "HOUR", "MINUTE", "QUARTER"];
const ViewType = ["ALL", "FAVORITE"];
const SortType = ["RANDOM", "DATE", "AUTHOR", "NAME"];
const Theme = ["classic", "horror", "modern", "playful"];

const collectionTypeOptions = ["All", "Favorite", "My Klydos", "Premium"];
function DeviceModal(props: any) {
  const setShowModal = props.setShowModal;
  const [showKlydoViewingModal, setShowKlydoViewingModal] = useState(false);
  const [data] = useState(props.row as Device);
  const [hideDev, setHideDev] = useState(
    data.hideDev ? data.hideDev.value : true,
  );
  const [currentKlydo, setCurrentKlydo] = useState(
    data.klydo?.id || "f90e5246-a4b2-4bf5-81ee-5f8d22241074",
  );
  const [timeOffset, setTimeOffset] = useState(
    data.timeOffset ? data.timeOffset.value : 0,
  );
  const [updateDevApp, setUpdateDevApp] = useState(false);
  const [updateVersion, setUpdateVersion] = useState(
    firebaseService.general?.last,
  );
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateAppLoader, setUpdateAppLoader] = useState(false);
  const [schedualOn, setSchedualOn] = useState(
    data.nightMode ? data.nightMode.value === "SCHEDULE" : false,
  );
  const [errors, setErrors] = useState("");
  const [validated, setValidated] = useState(false);
  const [previewKlydo, setPreviewKlydo] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [warning, setWarning] = useState("");
  const [klydoAnalyticData, setKlydoAnalyticData] = useState<
    Array<{
      clockTime: Date;
      displayTime: number;
      klydo?: Klydo;
      autoSwitch: boolean;
      favorite: boolean;
      collectionType: string;
    }>
  >();
  const [tableId] = useState("klydo-history");
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [adminRegDetails, setAdminRegDetails] = useState<{
    country?: string;
    state?: string;
    city?: string;
    name?: string;
    email?: string;
  }>();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    if (form.checkValidity()) {
      setValidated(true);
      setPreviewKlydo(true);
      getTheRightID().then((id) => {
        firebaseService
          .sendComandToClock(data.id, "previewKlydo", id)
          .then(() => setPreviewKlydo(false));
      });
    }
  };

  async function getTheRightID() {
    let klydo = await firebaseService.findItem<Klydo>(
      "klydos",
      "idf",
      currentKlydo,
    );
    if (!klydo) return currentKlydo;
    else return klydo.id;
  }

  const imgOrVideo = (klydo: Klydo) => {
    if (klydo.id === "9d371dd0-f44a-4d4d-8aeb-a927a788c978")
      return (
        <img
          style={{ borderRadius: 50 + "%" }}
          loading="lazy"
          height={96}
          width={96}
          src={bb}
          alt="klydo"
        />
      );
    const url = klydo.loopUrl;
    if (url) {
      if (isVideo(url) === "video")
        return (
          <video
            muted={true}
            style={{ borderRadius: 50 + "%" }}
            loop
            autoPlay
            height={96}
            width={96}
            src={url}
          />
        );
      if (isVideo(url) === "image")
        return (
          <img
            style={{ borderRadius: 50 + "%" }}
            loading="lazy"
            height={96}
            width={96}
            src={resizeCloudinary(url, 96)}
            alt="klydo"
          />
        );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>File type not supported</p>
      </div>
    );
  };

  const tooltip = (klydo: Klydo) => (
    <Tooltip id="tooltip">{imgOrVideo(klydo)}</Tooltip>
  );

  const tableColumn: Array<colType> = [
    {
      sort: true,
      label: "Clock Time",
      field: "clockTime",
    },
    {
      size: 170,
      sort: true,
      label: "Display Time (mos:day:hrs:min:sec)",
      field: "displayTime",
    },
    {
      sort: true,
      label: "Klydo Id",
      field: "idf",
    },
    {
      sort: true,
      label: "Auto Switch",
      field: "autoSwitch",
    },
    {
      sort: true,
      label: "Favorite",
      field: "favorite",
    },
    {
      sort: true,
      label: "Collection Type",
      field: "collectionType",
    },
  ];
  const tableRows = (
    r: {
      clockTime: Date;
      displayTime: number;
      klydo?: Klydo;
      autoSwitch: boolean;
      favorite: boolean;
      collectionType: number;
    },
    key: string,
  ) => {
    return {
      clockTime: r.clockTime.toLocaleString("en-GB"),
      displayTime: secondsToTime(r.displayTime),
      idf: (
        <OverlayTrigger
          placement="top"
          overlay={r.klydo ? tooltip(r.klydo) : <>"no-klydo"</>}
        >
          <NavLink
            to="/admin/klydos"
            onClick={() => {
              r.klydo &&
                localStorage.setItem(
                  "klydossearch",
                  r.klydo?.idf || r.klydo?.id,
                );
            }}
          >
            {r.klydo?.idf || "-No klydo available"}
          </NavLink>
        </OverlayTrigger>
      ),
      autoSwitch: r.autoSwitch.toString(),
      favorite: r.favorite.toString(),
      collectionType: collectionTypeOptions[r.collectionType],
      key: key,
    };
  };

  return (
    <>
      <Modal.Header style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}>
        <h4>Device config</h4>
        <i
          className="nc-icon nc-simple-remove"
          style={{ marginTop: 15 }}
          onClick={() => setShowModal(false)}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Info
                </Card.Header>
                <hr />
                <Card.Body>
                  <Row style={{ marginBottom: 12 }}>
                    <Col
                      className="pr-1"
                      md="12"
                      title="Device id"
                      style={{ marginLeft: 20 }}
                    >
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Device ID:</b> {data.id}
                      </h5>
                      <br />
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Friendly ID:</b> {data.idf}
                      </h5>
                      <br />
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Order:</b>{" "}
                        {data.order ? (
                          data.order.substring(0, 2) === "P2" ? (
                            <Link
                              to={`https://admin.shopify.com/store/klydo-clock/orders?query=${data.order}`}
                              target="_blank"
                            >
                              {data.order}
                            </Link>
                          ) : (
                            `${data.order}`
                          )
                        ) : (
                          "Unknown"
                        )}
                      </h5>
                    </Col>
                    <Col
                      className="pr-1"
                      md="12"
                      title="Device current installed version"
                      style={{ marginLeft: 20 }}
                    >
                      <b>Version: </b>
                      <NavLink
                        className="pl-2"
                        to="/admin/versions"
                        style={{ display: "inline" }}
                        onClick={() => {
                          localStorage.setItem("versionssearch", data.version);
                        }}
                      >
                        {data.version}
                      </NavLink>
                    </Col>
                    <Col
                      className="pr-1"
                      md="12"
                      title="Device's user"
                      style={{ marginLeft: 20 }}
                    >
                      {data.registerProduct ? (
                        <h5 className="pl-2" style={{ display: "inline" }}>
                          <b>Registered at:</b>{" "}
                          {data.registerProduct?.toLocaleDateString()}
                          <FaTrash
                            style={{ margin: "8px" }}
                            className="nc-icon nc-simple-remove"
                            onClick={(e) => {
                              e.stopPropagation();
                              firebaseService.reomveRegister(data.id);
                            }}
                          />
                        </h5>
                      ) : (
                        <h5 className="pl-2" style={{ display: "inline" }}>
                          <b>Not registered:</b>{" "}
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRegisterModal(true);
                            }}
                          >
                            Register device
                          </Button>
                        </h5>
                      )}
                    </Col>
                    {data.user?.value && (
                      <Col
                        className="pr-1"
                        md="12"
                        title="Device's user"
                        style={{ marginLeft: 20 }}
                      >
                        <h5 className="pl-2" style={{ display: "inline" }}>
                          <b>User:</b> {data.user.value}
                        </h5>
                      </Col>
                    )}
                    {data.location && (
                      <Col className="pr-1" md="12" style={{ marginLeft: 20 }}>
                        <h5 className="pl-2" style={{ display: "inline" }}>
                          <b>Location:</b> {data.location.value.city},{" "}
                          {data.location.value.country_code}
                        </h5>
                      </Col>
                    )}
                    {data.clocktime?.timeZone && (
                      <Col className="pr-1" md="12" style={{ marginLeft: 20 }}>
                        <h5 className="pl-2" style={{ display: "inline" }}>
                          <b>Timezone:</b> {data.clocktime!.timeZone}
                        </h5>
                      </Col>
                    )}
                    {data.clientDetails && (
                      <div
                        style={{ borderRadius: "12px", borderStyle: "solid" }}
                      >
                        <Col
                          className="pr-1"
                          md="12"
                          style={{ marginLeft: 20 }}
                        >
                          <h5 className="pl-2" style={{ display: "inline" }}>
                            <b>Registration form data:</b>
                          </h5>
                        </Col>
                        {Object.keys(data.clientDetails).map(
                          (o) =>
                            data.clientDetails[o] && (
                              <Col
                                className="pr-1"
                                md="12"
                                style={{ marginLeft: 20 }}
                              >
                                <h5
                                  className="pl-2"
                                  style={{ display: "inline" }}
                                >
                                  <b>{o}: </b> {data.clientDetails[o]}
                                </h5>
                              </Col>
                            ),
                        )}
                      </div>
                    )}
                    <Col
                      className="pr-1"
                      md="12"
                      style={{ marginLeft: 20, marginBottom: 22 }}
                    >
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Auto created user:</b> {data.autoCreate.toString()}
                      </h5>
                    </Col>
                    <Col
                      className="pr-1"
                      md="12"
                      style={{ marginLeft: 20, marginBottom: 22 }}
                    >
                      <Button
                        variant="danger"
                        onClick={() => setShowRemoveModal(true)}
                      >
                        Reset device cloud data
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Klydo
                </Card.Header>
                <hr />
                <Card.Body>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Link to the current displayed Klydo in the editor"
                    style={{ marginLeft: 20 }}
                  >
                    <b>Current Klydo: </b>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a
                        className="pl-2"
                        style={{ display: "inline" }}
                        target="_blank"
                        rel="noreferrer"
                        href={
                          "https://klydo-editor.web.app/klydo/" +
                          getTheRightID()
                        }
                      >
                        <b>Open in editor</b>
                      </a>
                      <NavLink
                        to="/admin/klydos"
                        onClick={() => {
                          localStorage.setItem("klydossearch", currentKlydo);
                        }}
                      >
                        <b>View in Klydos table</b>
                      </NavLink>
                    </div>
                  </Col>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Chage the current Klydo by entering a Klydo id"
                    style={{ paddingBottom: 15, marginLeft: 20 }}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                      className="d-flex justify-content-between"
                    >
                      <Form.Group
                        controlId="formBasicPassword"
                        style={{ display: "flex" }}
                      >
                        <InputGroup className="pl-2">
                          <InputGroup.Text style={{ background: myColor }}>
                            Edit
                          </InputGroup.Text>
                          <Form.Control
                            style={{ background: myColor }}
                            isInvalid={errors !== ""}
                            value={currentKlydo}
                            placeholder="Current Klydo"
                            required
                            onChange={(e) => {
                              const input = e.target.value;
                              let idf = input.split("-").length === 3;
                              let validated =
                                firebaseService.findItem<Klydo>(
                                  "klydos",
                                  idf ? "idf" : "id",
                                  input,
                                ) !== undefined;
                              setValidated(validated);
                              setErrors(
                                validated ? "" : "There is no such klydo",
                              );
                              setCurrentKlydo(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {errors}
                        </Form.Control.Feedback>
                        {validated ? (
                          <Button type="submit">
                            {previewKlydo ? (
                              <>
                                <Spinner animation="border" size="sm" /> Save
                              </>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Form.Group>
                    </Form>
                    <Button
                      style={{ display: "inline-block", marginTop: "15px" }}
                      onClick={async () => {
                        setShowKlydoViewingModal(true);
                        firebaseService
                          .getKlydoAnalyticsByDevice(data.id)
                          .then(async (v) => {
                            const klydos =
                              await firebaseService.getData<Klydo>("klydos");
                            setKlydoAnalyticData(
                              v.map((a) => ({
                                clockTime: new Date(a.clock_time),
                                displayTime: a.display_time,
                                klydo: klydos?.find((k) => k.id === a.klydo_id),
                                autoSwitch: a.auto_switch.toString(),
                                favorite: a.favorite.toString(),
                                collectionType: a.collection_type,
                              })),
                            );
                          });
                      }}
                    >
                      Klydo viewing history
                    </Button>
                  </Col>
                </Card.Body>
              </Card>
              {data.gift && (
                <ButtonLoader
                  title="Delete Gift"
                  onClick={() => firebaseService.deleteGift(data.id)}
                />
              )}
            </Col>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Show Klydos
                </Card.Header>
                <hr />
                <Card.Body>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Show mode: fixed Klydo or changes every hour/day"
                  >
                    <SimpleDropdown
                      title="Show: "
                      list={Show}
                      default={data.show ? data.show.value : Show[1]}
                      onSelect={(s: string) => {
                        firebaseService
                          .updateConfig(data.id, "show", s)
                          .then(() => {
                            data.show = { time: new Date(), value: s };
                          });
                      }}
                    />
                  </Col>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Display mode: all Klydos or device favorites"
                  >
                    <SimpleDropdown
                      title="Display Mode: "
                      list={(() => {
                        const list = [...ViewType];
                        if (data.user?.value) list.push("MYKLYDOS");
                        if (data.premium) list.push("PREMIUM");
                        return list;
                      })()}
                      default={data.mode ? data.mode.value : ViewType[0]}
                      onSelect={function (s: string): void {
                        console.log(s);

                        // firebaseService.updateConfig(data.id, "mode", s)
                      }}
                    />
                  </Col>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Sort Klydos: random / by create date / by author / by name"
                  >
                    <SimpleDropdown
                      title="Sort Type: "
                      list={SortType}
                      default={data.sort ? data.sort.value : SortType[0]}
                      onSelect={function (s: string): void {
                        firebaseService
                          .updateConfig(data.id, "sort", s)
                          .then(() => {
                            data.sort = { time: new Date(), value: s };
                          });
                      }}
                    />
                  </Col>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Sorting order"
                    style={{ display: "flex", marginLeft: 20 }}
                  >
                    <SimpleSwitch
                      title="Sort Down"
                      default={data.sortDown ? data.sortDown.value : true}
                      onClick={(on) => {
                        firebaseService
                          .updateConfig(data.id, "sortDown", on)
                          .then(
                            () =>
                              (data.sortDown = { time: new Date(), value: on }),
                          );
                      }}
                      on={undefined}
                    />
                  </Col>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Sound
                </Card.Header>
                <hr />
                <Card.Body>
                  <Col className="pr-1" md="12" title="Sound theme">
                    <SimpleDropdown
                      title="Sound Theme: "
                      list={Theme}
                      default={data.theme ? data.theme.value : Theme[0]}
                      onSelect={function (s: string): void {
                        firebaseService
                          .updateConfig(data.id, "theme", s)
                          .then(
                            () => (data.theme = { time: new Date(), value: s }),
                          );
                      }}
                    />
                  </Col>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Switch chimes sound on/off"
                    style={{ display: "flex", marginLeft: 20 }}
                  >
                    <SimpleSwitch
                      title="Chimes"
                      on={undefined}
                      default={data.chimes ? data.chimes.value : true}
                      onClick={(on) =>
                        firebaseService
                          .updateConfig(data.id, "chimes", on)
                          .then(
                            () =>
                              (data.chimes = { time: new Date(), value: on }),
                          )
                      }
                    />
                  </Col>
                  <Col className="pr-1" md="12" title="Chimes volume">
                    <SimpleBar
                      title="Chimes Volume:"
                      max={100}
                      default={
                        data.chimesVol ? data.chimesVol.value * 100 : 100
                      }
                      onChange={(e) => {
                        firebaseService
                          .updateConfig(
                            data.id,
                            "chimesVol",
                            parseInt(e.target.value) / 100,
                          )
                          .then(
                            () =>
                              (data.chimesVol = {
                                time: new Date(),
                                value: parseInt(e.target.value) / 100,
                              }),
                          );
                      }}
                    />
                  </Col>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Switch ticks sound on/off"
                    style={{ display: "flex", marginLeft: 20 }}
                  >
                    <SimpleSwitch
                      title="Ticks"
                      default={data.ticks ? data.ticks.value : true}
                      onClick={(on: boolean) => {
                        firebaseService
                          .updateConfig(data.id, "ticks", on)
                          .then(
                            () =>
                              (data.ticks = { time: new Date(), value: on }),
                          );
                      }}
                      on={undefined}
                    />
                  </Col>
                  <Col className="pr-1" md="12" title="Ticks volume">
                    <SimpleBar
                      title="Ticks Volume"
                      max={100}
                      default={data.ticksVol ? data.ticksVol.value * 100 : 100}
                      onChange={(e) => {
                        firebaseService
                          .updateConfig(
                            data.id,
                            "ticksVol",
                            parseInt(e.target.value) / 100,
                          )
                          .then(
                            () =>
                              (data.ticksVol = {
                                time: new Date(),
                                value: parseInt(e.target.value) / 100,
                              }),
                          );
                      }}
                    />
                  </Col>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Switch ticks sound on/off"
                    style={{ display: "flex", marginLeft: 20 }}
                  >
                    <SimpleSwitch
                      title="Silent times"
                      default={
                        data.quietTimesOn ? data.quietTimesOn.value : true
                      }
                      onClick={(on: boolean) => {
                        firebaseService
                          .updateConfig(data.id, "quietTimesOn", on)
                          .then(
                            () =>
                              (data.quietTimesOn = {
                                time: new Date(),
                                value: on,
                              }),
                          );
                      }}
                      on={undefined}
                    />
                  </Col>
                  <Col className="pr-1" md="12" title="Silent Times">
                    <Row style={{ paddingLeft: 20 }}>
                      <Col md="auto">
                        <SimpleTimePicker
                          title={"From:"}
                          onChange={(e) => {
                            firebaseService
                              .updateConfig(data.id, "quietTimes", {
                                from: e.target.value,
                                until: data.quietTimes
                                  ? data.quietTimes.value.until
                                  : "07:00",
                              })
                              .then(
                                () =>
                                  (data.quietTimes = {
                                    time: new Date(),
                                    value: {
                                      from: e.target.value,
                                      until: data.quietTimes
                                        ? data.quietTimes.value.until
                                        : "07:00",
                                    },
                                  }),
                              );
                          }}
                          default={
                            data.quietTimes
                              ? data.quietTimes.value.from
                              : "22:00"
                          }
                        />
                      </Col>
                      <Col md="auto">
                        <SimpleTimePicker
                          title={"Until:"}
                          onChange={(e) => {
                            firebaseService
                              .updateConfig(data.id, "quietTimes", {
                                from: data.quietTimes
                                  ? data.quietTimes.value.from
                                  : "07:00",
                                until: e.target.value,
                              })
                              .then(
                                () =>
                                  (data.quietTimes = {
                                    time: new Date(),
                                    value: {
                                      from: data.quietTimes
                                        ? data.quietTimes.value.from
                                        : "07:00",
                                      until: e.target.value,
                                    },
                                  }),
                              );
                          }}
                          default={
                            data.quietTimes
                              ? data.quietTimes.value.until
                              : "07:00"
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Display
                </Card.Header>
                <hr />
                <Card.Body>
                  <Col className="pr-1" md="12" title="Screen brightness">
                    <SimpleBar
                      title={"Brightness:"}
                      onChange={(e) => {
                        firebaseService
                          .updateConfig(
                            data.id,
                            "brightness",
                            Number(e.target.value),
                          )
                          .then(
                            () =>
                              (data.brightness = {
                                time: new Date(),
                                value: Number(e.target.value),
                              }),
                          );
                      }}
                      max={10}
                      default={data.brightness ? data.brightness.value : 10}
                    />
                  </Col>
                  {/* <Col
                                        className="pr-1"
                                        md="12"
                                        title="Switch screen sensor on/off"
                                        style={{ display: "flex", marginLeft: 20 }}
                                    >
                                        <SimpleSwitch
                                            title="Sensor Screen"
                                            on={undefined}
                                            default={data.auto ? data.auto.value : false}
                                            onClick={(on) =>
                                                firebaseService.updateConfig(data.id, "auto", on)
                                            }
                                        />
                                    </Col> */}
                  <Col
                    className="pr-1 mt-2"
                    md="12"
                    title="Night mode: on schedual \ auto \ off"
                  >
                    <SimpleDropdown
                      title="Night mode: "
                      list={NightMode}
                      default={
                        data.nightMode ? data.nightMode.value : NightMode[2]
                      }
                      onSelect={(s: string) => {
                        setSchedualOn(s === "SCHEDULE");
                        firebaseService
                          .updateConfig(data.id, "nightMode", s)
                          .then(
                            () =>
                              (data.nightMode = { time: new Date(), value: s }),
                          );
                      }}
                    />
                  </Col>
                  {schedualOn && (
                    <Col
                      className="pr-1 mt-2 mb-1"
                      md="12"
                      title="Night Mode Times"
                    >
                      <Row style={{ paddingLeft: 20 }}>
                        <Col md="auto">
                          <SimpleTimePicker
                            title={"From:"}
                            onChange={(e) => {
                              firebaseService
                                .updateConfig(data.id, "nightModeTimes", {
                                  from: e.target.value,
                                  until: data.nightModeTimes
                                    ? data.nightModeTimes.value.until
                                    : "07:00",
                                })
                                .then(
                                  () =>
                                    (data.nightModeTimes = {
                                      time: new Date(),
                                      value: {
                                        from: e.target.value,
                                        until: data.nightModeTimes
                                          ? data.nightModeTimes.value.until
                                          : "07:00",
                                      },
                                    }),
                                );
                            }}
                            default={
                              data.nightModeTimes
                                ? data.nightModeTimes.value.from
                                : "22:00"
                            }
                          />
                        </Col>
                        <Col md="auto">
                          <SimpleTimePicker
                            title={"Until:"}
                            onChange={(e) => {
                              firebaseService
                                .updateConfig(data.id, "nightModeTimes", {
                                  from: data.nightModeTimes
                                    ? data.nightModeTimes.value.from
                                    : "22:00",
                                  until: e.target.value,
                                })
                                .then(
                                  () =>
                                    (data.nightModeTimes = {
                                      time: new Date(),
                                      value: {
                                        from: data.nightModeTimes
                                          ? data.nightModeTimes.value.from
                                          : "22:00",
                                        until: e.target.value,
                                      },
                                    }),
                                );
                            }}
                            default={
                              data.nightModeTimes
                                ? data.nightModeTimes.value.until
                                : "07:00"
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Dev
                </Card.Header>
                <hr />
                <Card.Body>
                  <Row>
                    <Col
                      className="pr-1"
                      md="12"
                      title="Switch dev mode in device on/off"
                      style={{ display: "flex", marginLeft: 20 }}
                    >
                      <SimpleSwitch
                        title="Dev"
                        on={undefined}
                        default={!hideDev}
                        onClick={(on) =>
                          firebaseService
                            .updateConfig(data.id, "hideDev", on ? false : true)
                            .then(
                              () =>
                                (data.hideDev = {
                                  time: new Date(),
                                  value: !on,
                                }),
                            )
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="pr-1"
                      md="12"
                      title="Switch meta window in device on/off"
                      style={{ display: "flex", marginLeft: 20 }}
                    >
                      <SimpleSwitch
                        title="Show metadata"
                        on={["", ""]}
                        default={data.meta ? data.meta.value : false}
                        onClick={(on) =>
                          firebaseService
                            .updateConfig(data.id, "meta", on)
                            .then(
                              () =>
                                (data.meta = { time: new Date(), value: on }),
                            )
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="pr-1"
                      md="12"
                      title="join our BETA program"
                      style={{ display: "flex", marginLeft: 20 }}
                    >
                      <SimpleSwitch
                        title="Join BETA"
                        on={["", ""]}
                        default={data.beta ?? false}
                        onClick={(on) =>
                          firebaseService
                            .updateConfig(data.id, "beta", on)
                            .then(() => (data.beta = on))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="pr-1"
                      md="12"
                      title="set auto restart delay 0 to disable"
                      style={{ display: "flex", marginLeft: 20 }}
                    >
                      <Editable
                        title="Show metadata"
                        default={
                          (data.autoRestart
                            ? data.autoRestart.value / 60 / 60 / 1000
                            : 0) + ""
                        }
                        onSet={(value) =>
                          firebaseService
                            .updateConfig(
                              data.id,
                              "autoRestart",
                              parseInt(value) * 60 * 60 * 1000,
                            )
                            .then((r) => {
                              data.autoRestart = {
                                time: new Date(),
                                value: parseInt(value) * 60 * 60 * 1000,
                              };
                            })
                        }
                        editIcon={<FaPowerOff />}
                      />
                    </Col>
                    `
                  </Row>
                  `
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor, paddingBottom: 15 }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Premium klydo packages
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <PackList
                    device={data}
                    onSave={(packs: string[]) =>
                      firebaseService.saveDevicePremium(data, packs)
                    }
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Time Offset
                </Card.Header>
                <hr />
                <Card.Body>
                  <Col
                    className="pr-1"
                    md="12"
                    title="Set time offset of clock in minutes"
                    style={{ marginBottom: 10, marginLeft: 20 }}
                  >
                    <Form>
                      <InputGroup className="pl-2">
                        <b style={{ paddingTop: 5 }}>
                          Time Offset (in minutes):{" "}
                        </b>
                        <InputGroup.Text style={{ background: myColor }}>
                          Edit
                        </InputGroup.Text>
                        <Form.Control
                          style={{ background: myColor }}
                          onChange={(e) => {
                            setTimeOffset(e.target.value);
                            firebaseService
                              .updateConfig(
                                data.id,
                                "timeOffset",
                                Number(e.target.value),
                              )
                              .then(
                                () =>
                                  (data.meta = {
                                    time: new Date(),
                                    value: Number(e.target.value),
                                  }),
                              );
                          }}
                          defaultValue={timeOffset}
                          type="text"
                        />
                      </InputGroup>
                    </Form>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Updates
                </Card.Header>
                <hr />
                <Card.Body>
                  <Row>
                    <Col style={{ margin: 8 }}>
                      <ButtonLoader
                        variant="outline-dark"
                        style={{ color: "#000000" }}
                        title="Update App"
                        onClick={() =>
                          firebaseService.sendComandToClock(
                            data.id,
                            "updateApp",
                            "",
                          )
                        }
                      ></ButtonLoader>
                      {/* <Button variant="outline-dark" style={{color:'#000000'}}
                                                onClick={() => { setShowUpdateModal(true) }}
                                            > Update App
                                            </Button> */}
                    </Col>
                    <Col style={{ margin: 8 }}>
                      <ButtonLoader
                        variant="outline-dark"
                        style={{ color: "#000000" }}
                        title="Update Data"
                        onClick={() =>
                          firebaseService.sendComandToClock(
                            data.id,
                            "updateData",
                            "",
                          )
                        }
                      ></ButtonLoader>
                    </Col>
                    <Col style={{ margin: 8 }}>
                      <ButtonLoader
                        variant="outline-dark"
                        style={{ color: "#000000" }}
                        title="Reset Device"
                        onClick={() =>
                          firebaseService.sendComandToClock(
                            data.id,
                            "reset",
                            "",
                          )
                        }
                      ></ButtonLoader>
                    </Col>
                    <Col md="4" sm="4" style={{ margin: 8 }}>
                      <ButtonLoader
                        variant="outline-dark"
                        style={{ color: "#000000" }}
                        title="pair remote"
                        onClick={() =>
                          firebaseService.sendComandToClock(
                            data.id,
                            "bluetooth",
                            "",
                          )
                        }
                      ></ButtonLoader>
                    </Col>
                    <Col
                      md="4"
                      sm="4"
                      style={{ marginTop: 8, marginBottom: 8, paddingLeft: 0 }}
                    >
                      <ButtonLoader
                        variant="outline-dark"
                        style={{ color: "#000000" }}
                        title="reset network"
                        onClick={() =>
                          firebaseService.sendComandToClock(
                            data.id,
                            "resetNetwork",
                            "",
                          )
                        }
                      ></ButtonLoader>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <div className="modal-footer justify-content-end">
        <Button
          className="btn-simple right"
          type="button"
          variant="link"
          onClick={() => setShowModal(false)}
        >
          OK
        </Button>
      </div>
      <Modal
        centered
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
      >
        <Container fluid>
          <Modal.Header style={{ marginLeft: 24 }}>
            <h4>Update App</h4>
            <i
              className="nc-icon nc-simple-remove"
              style={{ marginTop: 15 }}
              onClick={() => setShowUpdateModal(false)}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <Form className="justify-content-between">
              <InputGroup className="pl-2">
                <b>Update App: </b>
                <InputGroup.Text>Version Number</InputGroup.Text>
                <Form.Control
                  onChange={(e) => {
                    setUpdateVersion(parseInt(e.target.value));
                  }}
                  defaultValue={updateVersion}
                  type="text"
                />
              </InputGroup>
              <Form.Check className="mb-1 pl-0">
                <Form.Check.Label style={{ color: "black", paddingLeft: 0 }}>
                  <Form.Check.Input
                    checked={updateDevApp}
                    onChange={() => {
                      setUpdateDevApp(!updateDevApp);
                    }}
                  ></Form.Check.Input>
                  <span className="form-check-sign"></span>
                  <h5>
                    <b>Dev App</b>
                  </h5>
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-1 pl-0">
                <Form.Check.Label style={{ color: "black", paddingLeft: 0 }}>
                  <Form.Check.Input
                    checked={!updateDevApp}
                    onChange={() => {
                      setUpdateDevApp(!updateDevApp);
                    }}
                  ></Form.Check.Input>
                  <span className="form-check-sign"></span>
                  <h5>
                    <b>Public App</b>
                  </h5>
                </Form.Check.Label>
              </Form.Check>
              <div className="modal-footer justify-content-end">
                <Button
                  onClick={async () => {
                    setUpdateAppLoader(true);
                    if (updateDevApp) setHideDev(false);
                    await firebaseService.sendComandToClock(
                      data.id,
                      "updateApp",
                      updateVersion === firebaseService.general?.last
                        ? ""
                        : updateVersion + "",
                    );
                    setUpdateAppLoader(false);
                    setShowUpdateModal(false);
                  }}
                >
                  {updateAppLoader ? (
                    <>
                      <Spinner animation="border" size="sm" /> Update
                    </>
                  ) : (
                    "Update"
                  )}
                </Button>{" "}
              </div>
            </Form>
          </Modal.Body>
        </Container>
      </Modal>
      {showKlydoViewingModal && (
        <Modal
          dialogClassName="custom-dialog-style"
          contentClassName="custom-modal-style"
          show={showKlydoViewingModal}
          onHide={() => setShowKlydoViewingModal(false)}
        >
          <Modal.Header
            style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}
          >
            <h4>Klydo Viewing History</h4>
            <i
              className="nc-icon nc-simple-remove"
              style={{ marginTop: 15 }}
              onClick={() => setShowKlydoViewingModal(false)}
            ></i>
          </Modal.Header>

          {klydoAnalyticData && (
            <MsTable
              id={tableId}
              rowBuild={tableRows}
              search={(row: any, val: string) => {
                return (
                  row.klydo?.tags?.find(
                    (t: string) => t.toLowerCase() === val,
                  ) | row.klydo?.idf.includes(val) ||
                  row.klydo?.id.includes(val) ||
                  row.klydo?.author?.toLowerCase().includes(val) ||
                  row.klydo?.name.toLowerCase().includes(val)
                );
              }}
              initSort={"clockTime"}
              data={klydoAnalyticData}
              cols={tableColumn}
              title=""
            />
          )}
          {!klydoAnalyticData && (
            <div id="fuse-splash-screen-version2">
              <div className="center">
                <div className="spinner-wrapper">
                  <div className="spinner">
                    <div className="inner">
                      <div className="gap" />
                      <div className="left">
                        <div className="half-circle" />
                      </div>
                      <div className="right">
                        <div className="half-circle" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
      <Modal show={showRemoveModal}>
        <Modal.Body>
          <h4>Are you sure you want to reset the cloud data of this device?</h4>
          <h6>{data.idf}</h6>
          {warning ? (
            <p style={{ color: "red" }}>{warning}</p>
          ) : (
            <>
              <Button
                style={{ width: 180, margin: 10 }}
                onClick={() => setShowRemoveModal(false)}
              >
                Cancel
              </Button>
              <ButtonLoader
                variant="danger"
                style={{ width: 180, margin: 10 }}
                title="Yes"
                onClick={() => {
                  return new Promise<void>((v, x) => {
                    firebaseService
                      .resetDeviceData(data.id, data.idf)
                      .then(() => {
                        setWarning("");
                        setShowRemoveModal(false);
                        setShowModal(false);
                        v();
                      })
                      .catch((e) => {
                        setWarning(e.message || "");
                        x();
                      });
                  });
                }}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showRegisterModal}
        onHide={() => setShowRegisterModal(false)}
      >
        <Modal.Header
          style={{
            padding: 0,
            marginLeft: "10px",
            marginRight: "5px",
            marginBottom: "24px",
          }}
        >
          <h4
            style={{
              margin: 0,
              paddingTop: "16px",
              paddingLeft: "14px",
              fontWeight: "bold",
            }}
          >
            Register device
          </h4>
          {
            <i
              className="nc-icon nc-simple-remove btn"
              style={{ bottom: "7px", position: "relative", padding: 0 }}
              onClick={() => setShowRegisterModal(false)}
            ></i>
          }
        </Modal.Header>
        <Modal.Body>
          <b style={{ marginBottom: "5px" }}>Client details (optional):</b>
          <Form.Control
            style={{
              marginBottom: "5px",
              zIndex: 0,
              borderRadius: "0.375rem",
              borderLeft: "outset",
              borderRight: "inset",
              border: "1px solid #969696",
            }}
            isValid={validated}
            defaultValue={data.clientDetails?.country}
            placeholder="Country"
            required
            type="text"
            onChange={(e) => {
              setAdminRegDetails({
                ...adminRegDetails,
                country: e.target.value,
              });
            }}
          ></Form.Control>
          <Form.Control
            style={{
              marginBottom: "5px",
              zIndex: 0,
              borderRadius: "0.375rem",
              borderLeft: "outset",
              borderRight: "inset",
              border: "1px solid #969696",
            }}
            isValid={validated}
            defaultValue={data.clientDetails?.state}
            placeholder="State"
            type="text"
            onChange={(e) => {
              setAdminRegDetails({ ...adminRegDetails, state: e.target.value });
            }}
          ></Form.Control>
          <Form.Control
            style={{
              marginBottom: "5px",
              zIndex: 0,
              borderRadius: "0.375rem",
              borderLeft: "outset",
              borderRight: "inset",
              border: "1px solid #969696",
            }}
            isValid={validated}
            placeholder="City"
            defaultValue={data.clientDetails?.city}
            type="text"
            onChange={(e) => {
              setAdminRegDetails({ ...adminRegDetails, city: e.target.value });
            }}
          ></Form.Control>
          <Form.Control
            style={{
              marginBottom: "5px",
              zIndex: 0,
              borderRadius: "0.375rem",
              borderLeft: "outset",
              borderRight: "inset",
              border: "1px solid #969696",
            }}
            isValid={validated}
            defaultValue={data.clientDetails?.email}
            placeholder="Email"
            type="text"
            onChange={(e) => {
              setAdminRegDetails({ ...adminRegDetails, email: e.target.value });
            }}
          ></Form.Control>
          <Form.Control
            style={{
              zIndex: 0,
              borderRadius: "0.375rem",
              borderLeft: "outset",
              borderRight: "inset",
              border: "1px solid #969696",
            }}
            isValid={validated}
            defaultValue={data.clientDetails?.name}
            placeholder="Name"
            type="text"
            onChange={(e) => {
              setAdminRegDetails({ ...adminRegDetails, name: e.target.value });
            }}
          ></Form.Control>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <ButtonLoader
              title="Register"
              className="btn"
              onClick={() =>
                firebaseService.registerDevice(data, adminRegDetails)
              }
            ></ButtonLoader>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeviceModal;
