/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Button, Row } from "react-bootstrap";
import firebaseService from "../../firebase_service/firebaseService";
import Clock from "../Widgets/Clock";

function Header() {
  //const location = useLocation(); can b removed??
  const mobileSidebarToggle = (e: any) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      node.parentElement!.removeChild(node);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Row className="justify-content-between" style={{ width: 100 + "%" }}>
          <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
              onClick={mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
            <span className="navbar-toggler-bar burger-lines"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="nav mr-auto"
              navbar
              style={{
                width: 100 + "%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Clock tz="America/Los_Angeles"></Clock>
              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    firebaseService.logOut();
                  }}
                >
                  <span className="no-icon">Log out</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Row>
      </Container>
    </Navbar>
  );
}

export default Header;
