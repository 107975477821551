import { useState } from "react";
import { socialMediaList } from "../../utils/util";
import { Button, Popover, OverlayTrigger, Form } from "react-bootstrap";
import { FaEdit, FaSave, FaPlus } from "react-icons/fa";
import { SocialNetwork } from "Types";
import KSocialIcon from "./KSocialIcons";
const EditableSocialNetwork = (props: {
  net: SocialNetwork;
  onChange: (net: SocialNetwork) => void;
  onRemove: () => void;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState("");

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "fit-content",
          margin: 5,
          padding: 0,
          borderRadius: "20px",
          border: open ? "1px solid black" : "0",
          background: "#FFFFFF",
        }}
      >
        <KSocialIcon
          network={props.net.network}
          title={props.net.network}
          style={{ height: 35, width: 35 }}
          onClick={() => setOpen(!open)}
        ></KSocialIcon>
        {open && edit === "" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href={props.net.link}>{props.net.link}</a>
            <FaEdit
              style={{ margin: 5 }}
              title="Edit"
              onClick={() => setEdit(props.net.link)}
            ></FaEdit>
          </div>
        )}
        {open && edit && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Control
              autoFocus
              defaultValue={props.net.link}
              onChange={(e) => setEdit(e.target.value)}
              style={{ border: 0 }}
            ></Form.Control>
            <FaSave
              style={{ margin: 5 }}
              title="Save"
              onClick={() => {
                props.onChange({ network: props.net.network, link: edit });
                setEdit("");
              }}
            ></FaSave>
          </div>
        )}
      </div>
      <i
        className="nc-icon nc-simple-add"
        title="Remove"
        onClick={props.onRemove}
        style={{
          position: "relative",
          top: 5,
          left: -15,
          fontSize: "0.7rem",
          fontWeight: 800,
          width: "fit-content",
          height: "fit-content",
          borderRadius: "50%",
          color: "black",
          background: "red",
          rotate: "45deg",
        }}
      ></i>
    </>
  );
};
const SocialNetworkEdit = (props: {
  list: Array<SocialNetwork>;
  onChange: (list: Array<SocialNetwork>) => void;
}): JSX.Element => {
  const [list, setList] = useState(props.list);
  const [network, setNetwork] = useState("");
  const [link, setLink] = useState("");

  return (
    <>
      <div style={{ display: "flex" }}>
        {list.map((n, i) => (
          <EditableSocialNetwork
            key={i}
            net={n}
            onChange={(net) => {
              list[i] = net;
              setList([...list]);
              props.onChange(list);
            }}
            onRemove={() => {
              list.splice(i, 1);
              setList([...list]);
              props.onChange(list);
            }}
          />
        ))}
      </div>
      {network === "" ? (
        <OverlayTrigger
          trigger="click"
          rootClose
          overlay={
            <Popover>
              <div style={{ width: 250, padding: 10 }}>
                {socialMediaList.map((n) => (
                  <KSocialIcon
                    key={n}
                    network={n}
                    title={n}
                    style={{ height: 35, width: 35, margin: 5 }}
                    onClick={() => setNetwork(n)}
                  ></KSocialIcon>
                ))}
              </div>
            </Popover>
          }
        >
          {list.length === 0 ? (
            <Button>add social network</Button>
          ) : (
            <div
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: 35,
                width: 35,
                background: "lightgreen",
                borderRadius: "50%",
              }}
            >
              <FaPlus></FaPlus>
            </div>
          )}
        </OverlayTrigger>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            margin: 5,
            padding: 0,
            borderRadius: "20px",
            border: "1px solid black",
            background: "#FFFFFF",
          }}
        >
          <KSocialIcon
            network={network}
            title={network}
            style={{ height: 35, width: 35 }}
          ></KSocialIcon>
          <Form.Control
            autoFocus
            onChange={(e) => setLink(e.target.value)}
            style={{ border: 0 }}
          ></Form.Control>
          <FaSave
            style={{ margin: 5 }}
            title="Save"
            onClick={() => {
              if (link === "") {
                setNetwork("");
                return;
              }
              list.push({ network: network, link: link });
              setList([...list]);
              props.onChange(list);
              setNetwork("");
              setLink("");
            }}
          ></FaSave>
        </div>
      )}
    </>
  );
};

export default SocialNetworkEdit;
