import { SocialIcon } from "react-social-icons";
import {
  FaPatreon,
  FaDeviantart,
  FaArtstation,
  FaWikipediaW,
} from "react-icons/fa6";
import rarible from "../../assets/img/rarible.png";
import superRare from "../../assets/img/superrare.png";
import foundation from "../../assets/img/foundation.png";
import { MyImdb, MyNitfy } from "../../assets/img/myIcons";

const KSocialIcon = (props: any) => {
  const myStyle = {
    display: "inline-block",
    position: "relative",
    overflow: "hidden",
    verticalAlign: "middle",
    border: 0,
    padding: 0,
    background: "transparent",
  };
  if (props.network === "imdb")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            color: "black",
            background: "#E0AB00",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        >
          <MyImdb></MyImdb>
        </div>
      </button>
    );
  if (props.network === "nifty")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            color: "black",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        >
          <MyNitfy></MyNitfy>
        </div>
      </button>
    );
  if (props.network === "wikipedia")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            color: "black",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        >
          <FaWikipediaW style={{ width: 24, height: 24 }}></FaWikipediaW>
        </div>
      </button>
    );
  if (props.network === "patreon")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            color: "red",
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        >
          <FaPatreon></FaPatreon>
        </div>
      </button>
    );
  if (props.network === "deviantart")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            color: "#00e59b",
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        >
          <FaDeviantart></FaDeviantart>
        </div>
      </button>
    );
  if (props.network === "artstation")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            color: "#61cdff",
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        >
          <FaArtstation></FaArtstation>
        </div>
      </button>
    );
  if (props.network === "rarible")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            background: "rgb(254, 218, 3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img style={{ width: 34, height: 34 }} src={rarible}></img>
        </div>
      </button>
    );
  if (props.network === "superrare")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            background: "#FAFAFA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img style={{ width: 34, height: 34 }} src={superRare}></img>
        </div>
      </button>
    );
  if (props.network === "foundation")
    return (
      <button onClick={props.onClick} style={{ ...props.style, ...myStyle }}>
        <div
          title={props.network}
          style={{
            background: "#FAFAFA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img style={{ width: 34, height: 34 }} src={foundation}></img>
        </div>
      </button>
    );
  return <SocialIcon {...props} />;
};
export default KSocialIcon;
