import { Klydo, Pack, Tag, User } from "../Types";
import { useCallback, useEffect, useState } from "react";
import { Modal, Button, Row, Form, Spinner } from "react-bootstrap";
import { isVideo, percentToPresent, resizeCloudinary } from "../utils/util";
import KlydoModal from "./KlydoModal";
import { FaTrash } from "react-icons/fa";
import firebaseService from "../firebase_service/firebaseService";
import { calcTimes } from "../components/Widgets/TimeCalc";
import ButtonLoader from "../components/Widgets/ButtonLoader";
import MsTable from "../components/Widgets/Table";
import ScheduledTasksAddModal from "./ScheduledTaskAddModal";
import { BsTag } from "react-icons/bs";
// eslint-disable-next-line import/no-unresolved
import useKlydos from "components/hooks/useKlydos";
import { debounce } from "lodash";
import { RiCalendarCheckLine } from "react-icons/ri";

const filterOptions = ["All", "Pool", "Not Pooled", "Not Scheduled"];

function Klydos() {
  const [data, setData] = useState<Array<Klydo>>();
  const [tags, setTags] = useState<Array<Tag>>();
  const [showDelete, setShowDelete] = useState<boolean | Klydo>(false);
  const [showScheduledTaskAddModal, setShowScheduledTaskAddModal] =
    useState(false);
  const [textSearch, setTextSearch] = useState<string>("");
  const [showAddToPacks, setShowAddToPacks] = useState<boolean>(false);
  const [localSelected, setLocalSelected] = useState<Array<Klydo>>([]);
  const [filterValue, setFilterValue] = useState(0);
  const [filterData, setFilterData] = useState<Array<Klydo>>();
  const [excludeIds, setExcludeIds] = useState<string[]>([]);
  const { data: ids, isLoading } = useKlydos(textSearch, {
    excludeIds,
  });
  const [isSearching, setIsSearching] = useState(false);
  const handleFreeSearch = useCallback(
    debounce((s) => setTextSearch(s), 500),
    [],
  );

  const loadMoreKlydos = async () => {
    setExcludeIds((prevExcludeIds) => [
      ...(prevExcludeIds ?? []),
      ...(ids || []),
    ]);
  };
  useEffect(() => {
    setExcludeIds([]);
  }, [textSearch]);

  useEffect(() => {
    if (ids) {
      setIsSearching(false);
    }
  }, [ids]);

  useEffect(() => {
    firebaseService.getData<Tag>("tags2").then(setTags);
    const l = (dt: Klydo[]) => {
      setData([...dt]);
    };
    firebaseService.listen<Klydo>("klydos", l);
    return () => {
      firebaseService.removeListener("klydos", l);
    };
  }, []);
  useEffect(() => {
    if (filterValue === 0) {
      //clear filters
      setFilterData(data);
      return;
    }
    if (filterValue === 3) {
      //filter not tasked typeof r.klydo !== 'string'
      setFilterData(data?.filter((k: Klydo) => !k.inTask));
      return;
    }
    setFilterData(
      data?.filter(
        (k: Klydo) =>
          (k.pool && filterValue === 1) || (!k.pool && filterValue === 2),
      ),
    );
  }, [filterValue, data]);

  const tableColumns: Array<{
    sort: boolean;
    field: string;
    label: string;
    size?: number;
    noHeadline?: boolean;
  }> = [
    {
      sort: false,
      field: "tag",
      label: "",
      size: 32,
    },
    {
      sort: true,
      label: "id",
      field: "idf",
      size: 200,
    },
    {
      sort: true,
      label: "author",
      field: "author",
      size: 200,
    },
    {
      sort: true,
      label: "name",
      field: "name",
      size: 200,
    },
    {
      sort: false,
      label: "gif",
      field: "image",
    },
    {
      sort: true,
      label: "date",
      field: "createdAt",
      size: 220,
    },
    {
      size: 150,
      sort: true,
      label: "Favorite",
      field: "favs",
    },
    {
      size: 128,
      sort: false,
      label: "Pool",
      field: "pool",
    },
    {
      size: 128,
      sort: false,
      label: "task",
      field: "task",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tableRows = (r: Klydo, key: number) => {
    return {
      idf: <p title={r.id}>{r.idf}</p>,
      image:
        !r.loopUrl || isVideo(r.loopUrl) === "none" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>File type not supported</p>
          </div>
        ) : (
          <div
            style={{
              borderRadius: "50%",
              width: 96,
              height: 96,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: r.crop?.top || 0 + "%",
                left: r.crop?.left || 0 + "%",
                width: `${r.crop?.zoom || 100}%`,
                aspectRatio: "1/1",
              }}
            >
              {isVideo(r.loopUrl) === "image" ? (
                <img
                  loading="lazy"
                  alt="gif"
                  src={resizeCloudinary(r.loopUrl, 96)}
                  style={{ width: "auto", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <video
                  muted={true}
                  src={resizeCloudinary(r.loopUrl, 96)}
                  loop
                  autoPlay
                  style={{ width: "auto", height: "100%", objectFit: "cover" }}
                />
              )}
            </div>
          </div>
        ),
      author: <p title={r.creator}>{r.author}</p>,
      name: r.name,
      createdAt: r.createdAt.toLocaleString("en-GB"),
      favs: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {r.favs || 0}
        </div>
      ),
      times: percentToPresent(calcTimes(r.times ?? [])),
      pool: r.unlisted ? (
        <span style={{ color: "red" }}>Unlisted</span>
      ) : (
        <Form.Switch
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          checked={r.pool || false}
          type="switch"
          onChange={(e) => {
            r.pool = e.currentTarget.checked;
            firebaseService.updateKlydo(r, { pool: e.currentTarget.checked });
          }}
          onClick={(e) => e.stopPropagation()}
          disabled={!!r.unlisted}
        />
      ),
      delete: (
        <FaTrash
          style={{ margin: "8px" }}
          className="nc-icon nc-simple-remove"
          onClick={(e) => {
            e.stopPropagation();
            setShowDelete(r);
          }}
        />
      ),
      task: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div title={"scheduled"}>
            <RiCalendarCheckLine color={r.inTask ? "green" : "grey"} />
          </div>
        </div>
      ),
      key: r.id,
      tag: r.tags?.length ? (
        <BsTag title={"tags:" + r.tags?.reduce((a, b) => a + "\n" + b, "")} />
      ) : (
        ""
      ),
    };
  };

  function genActions() {
    return (
      <div
        style={{
          width: "23%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setShowScheduledTaskAddModal(true)}
        >
          Add klydos To Task
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setShowDelete(true)}
        >
          Delete klydos
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setShowAddToPacks(true)}
        >
          Add to packs
        </Button>
      </div>
    );
  }

  const handleKlydoUpdate = (klydo: Klydo) => {
    if (klydo) {
      setData((data ?? []).map((k: Klydo) => (k.id === klydo?.id ? klydo : k)));
    }
  };
  function genButtons() {
    return (
      <div>
        <select
          defaultValue="All"
          onChange={(v) => {
            const value = v.target.value;
            setFilterValue(parseInt(value));
          }}
        >
          {filterOptions.map((v, i) => (
            <option value={i}>{v}</option>
          ))}
        </select>
      </div>
    );
  }
  return (
    <>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Free Search</Form.Label>
        <Form.Control
          onChange={(e) => {
            setIsSearching(true);
            handleFreeSearch(e.target.value);
          }}
          type="search"
          placeholder="Happy Klydo"
        />
      </Form.Group>
      {!isSearching && data && filterData && !isLoading ? (
        <MsTable
          id="klydos"
          cancelSort={!!(textSearch && ids)}
          buttons={genButtons()}
          actions={() => genActions()}
          onScrollDown={loadMoreKlydos}
          onAction={(selected: Array<Klydo>) => {
            setLocalSelected(selected);
          }}
          rowBuild={tableRows}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          search={(row: any, val: string) => {
            if (tags?.find((t: Tag) => t.name.toLowerCase() === val))
              return row.tags?.find((t: string) => t.toLowerCase() === val);
            return (
              row.idf.includes(val) ||
              row.id.includes(val) ||
              row.author?.toLowerCase().includes(val) ||
              row.name.toLowerCase().includes(val)
            );
          }}
          data={
            textSearch && (ids || excludeIds)
              ? Array.from(new Set([...(excludeIds || []), ...(ids || [])]))
                  .filter((id) => data.some((k: Klydo) => k.id === id))
                  .map((id) => data.find((d: Klydo) => d.id === id))
                  .filter(Boolean)
              : filterData
          }
          initSort={"createdAt"}
          cols={tableColumns}
          title="Klydos"
          handleChangedKlydo={handleKlydoUpdate}
          modal={KlydoModal}
        />
      ) : (
        <Spinner></Spinner>
      )}
      <ScheduledTasksAddModal
        open={showScheduledTaskAddModal}
        handleClose={(add: boolean) => {
          setShowScheduledTaskAddModal(add);
          //if (add) setSelected([])
        }}
        klydos={localSelected}
      />
      {showAddToPacks && (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <AddToPacksModal
          localSelected={localSelected}
          done={() => setShowAddToPacks(false)}
        />
      )}
      {showDelete && (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <DeleteModal
          done={() => setShowDelete(false)}
          localSelected={showDelete === true ? localSelected : undefined}
          toRemove={showDelete === true ? undefined : showDelete}
        ></DeleteModal>
      )}
    </>
  );
}

export default Klydos;

///Below are Delete and AddToPacks Modals

function AddToPacksModal(props: { localSelected: Klydo[]; done: () => void }) {
  const [allPacks, setAllPacks] = useState<Pack[]>();
  const [selectedPacks, setSelectedPacks] = useState<Pack[]>([]);
  const [error, setError] = useState<string>();
  useEffect(() => {
    const l = (packs: Pack[]) => {
      setAllPacks(packs);
    };
    firebaseService.listen<Pack>("packs", l);

    return () => firebaseService.removeListener("packs", l);
  }, []);

  const addKldsToPacks = async () => {
    setError(undefined);
    const prmss: Promise<void>[] = [];
    selectedPacks.forEach((pack) => {
      const funcAddKlydos = props.localSelected.filter(
        (klydo) => !klydo.tags?.includes(pack.tag),
      );
      if (funcAddKlydos.length) {
        if (!pack.klydos) pack.klydos = [];
        pack.klydos.push(...funcAddKlydos.map((kld) => kld.id));
        prmss.push(firebaseService.updatePack(pack));
      }
    });
    if (!prmss.length) {
      return Promise.reject("Chosen packs already contain all klydos");
    }
    return Promise.all(prmss);
  };

  return (
    <Modal show={true} onHide={() => props.done()}>
      <Modal.Header
        style={{
          padding: 0,
          marginLeft: "10px",
          marginRight: "5px",
          marginBottom: "24px",
        }}
      >
        <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
          Add klydos to packs
        </h4>
        <i
          style={{ bottom: "7px", position: "relative" }}
          className="nc-icon nc-simple-remove"
          onClick={() => {
            props.done();
          }}
        ></i>
      </Modal.Header>
      {allPacks && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "140px",
            overflowY: "auto",
          }}
        >
          {allPacks.map((pack) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 5,
                borderBottom: "1px solid #eee",
                marginLeft: "5px",
              }}
            >
              <input
                onChange={(event) => {
                  if (event.target.checked) selectedPacks.push(pack);
                  else selectedPacks.splice(selectedPacks.indexOf(pack), 1);
                  setSelectedPacks((prev) => prev?.slice());
                }}
                type="checkbox"
              />
              <p style={{ width: "10%", margin: 0 }}>{pack.name}</p>
              <p style={{ width: "10%", margin: 0 }}>{pack.tag}</p>
              <img
                style={{ borderRadius: 50 + "%" }}
                loading="lazy"
                height={48}
                width={48}
                alt="profile"
                src={pack.imageUrl}
              ></img>
            </div>
          ))}
        </div>
      )}
      {error && <p style={{ color: "red", fontSize: "12px" }}>{error}</p>}
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <Button
          style={{
            color: "#FF1020",
            borderColor: "#FF1020",
            margin: 5,
            justifySelf: "right",
            height: "fit-content",
            marginRight: "5px",
            padding: "0px 4px",
            marginTop: "5px",
          }}
          onClick={() => {
            props.done();
          }}
        >
          Close
        </Button>
        <ButtonLoader
          disabled={!selectedPacks.length}
          style={{
            margin: 5,
            height: "fit-content",
            marginRight: "5px",
            padding: "0px 4px",
            marginTop: "5px",
            color: "#417d9e",
          }}
          title="Save"
          onClick={async () => {
            return addKldsToPacks()
              .then(() => props.done())
              .catch(setError);
          }}
        >
          Save
        </ButtonLoader>
      </div>
    </Modal>
  );
}

function DeleteModal(props: {
  toRemove?: Klydo;
  localSelected?: Klydo[];
  done: () => void;
}) {
  const [deleteMsg, setDeleteMsg] = useState("");
  const [warning, setWarning] = useState("");
  return (
    <Modal show={true} onHide={() => props.done()}>
      <Modal.Header style={{ justifyContent: "right" }}>
        <i
          className="nc-icon nc-simple-remove"
          onClick={() => props.done()}
        ></i>
      </Modal.Header>
      <Modal.Body>
        {props.toRemove ? (
          <h4>
            Are you sure you want to remove klydo "{props.toRemove.name}" from
            public?
          </h4>
        ) : (
          <>
            <h4>Are you sure you want to remove these klydos?</h4>
            {props.localSelected!.map((k) => (
              <p key={k.id}>{k.name}</p>
            ))}
          </>
        )}
        <Row className="h-100">
          <Form.Control
            as="textarea"
            rows={4}
            value={deleteMsg}
            onChange={(e) => setDeleteMsg(e.target.value)}
          ></Form.Control>
        </Row>
        {warning && <p style={{ color: "red" }}>{warning}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ width: 180, margin: 10 }} onClick={() => props.done()}>
          No
        </Button>
        <ButtonLoader
          style={{ width: 180, margin: 10 }}
          title="Yes"
          onClick={async () => {
            if (!deleteMsg && props.localSelected) {
              setWarning("Must enter a message to delete");
              return;
            }
            if (!deleteMsg) {
              const user = await firebaseService.findItem<User>(
                "users",
                "uid",
                props.toRemove!.creator,
              );
              if (user?.lastRefreshTime) {
                setWarning("Must enter a message to delete");
                return;
              }
            }
            setWarning("");
            if (props.toRemove) {
              try {
                await firebaseService.acceptReview(
                  props.toRemove!,
                  true,
                  deleteMsg,
                );
                props.done();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (e: any) {
                setWarning(e.message || "an error occured");
              }
            } else {
              try {
                await Promise.all(
                  props.localSelected!.map((k) =>
                    firebaseService.acceptReview(k, true, deleteMsg),
                  ),
                );
                props.done();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (e: any) {
                setWarning(e.message || "an error occured");
              }
            }
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
